import React, { createContext, useContext, useState } from 'react';
import { getCamerasByBrand, getResolutionsForCamera, getFrameratesForCamera, getCodecsForCameraAndResolution } from '../data/cameraData';

const CalculatorContext = createContext();

// Default states
const defaultCalcState = {
  // Camera to File Size default state
  duration: '01:00:00:00',
  brand: 'ARRI',
  camera: 'ALEXA 35',
  resolution: '4.6K 3:2 Open Gate (4608 x 3164)',
  framerate: '23.976',
  codec: 'ARRIRAW',
  fileSize: '1.915',
  fileSizeUnit: 'TB',
  durationInput: '01:00:00:00',
  fileSizeInput: '1.915 TB',
  isDurationLocked: false,
  isFileSizeLocked: false,
  calculationMode: null,
  availableCameras: [],
  availableResolutions: [],
  availableFramerates: [],
  availableCodecs: [],
  copyStatus: '',
  isMobile: false,
  copiedLogIndex: null,
  copyDurationText: null,
  copyFileSizeText: null,
  logButtonText: null,
  shareButtonText: null,
  resetButtonText: null,
  previousResolution: '4.6K 3:2 Open Gate (4608 x 3164)'
};

const defaultFramingChartState = {
  brand: 'Sony',
  camera: 'VENICE 2 8K',
  resolution: '8.6K 3:2 (8640 x 5760)',
  showName: 'SHOW NAME',
  dopName: 'DOP NAME',
  safetyMargin: 5,
  showUhdOverlay: true,
  aspectRatio: '16:9',
  isAnamorphic: false,
  squeezeRatio: '1x',
  codec: 'Sony X-OCN XT',
  lensSqueeze: '1x'
};

export const CalculatorProvider = ({ children }) => {
  const [cameraCalcState, setCameraCalcState] = useState(defaultCalcState);
  const [framingChartState, setFramingChartState] = useState(defaultFramingChartState);
  const [activeTab, setActiveTab] = useState('calculator');

  // Aspect Ratio Calculator state
  const [aspectCalcState, setAspectCalcState] = useState({
    selectedRatio: "16:9",
    width: 1920,
    height: 1080,
    lockedField: null,
    customRatio: "",
    customRatios: [],
    isMobile: false,
    logButtonText: null,
    copyButtonText: null,
    shareButtonText: null,
    resetButtonText: null,
    calculationMode: null,
    isWidthLocked: false,
    isHeightLocked: false
  });

  // Bitrate Calculator state
  const [bitrateCalcState, setBitrateCalcState] = useState({
    durationInput: "01:00:00:00",
    bitrate: '100',
    bitrateUnit: 'MB/s',
    fileSize: '43.902',
    fileSizeUnit: 'GB',
    lockedField: null,
    calculationMode: null
  });

  // Dailies Calculator state
  const [dailiesState, setDailiesState] = useState({
    // Camera settings
    brand: 'ARRI',
    camera: 'ALEXA 35',
    resolution: '4.6K 3:2 Open Gate (4608 x 3164)',
    codec: 'ARRIRAW',
    framerate: '23.976',
    
    // Available options
    availableCameras: getCamerasByBrand('ARRI'),
    availableResolutions: getResolutionsForCamera('ALEXA 35'),
    availableFramerates: getFrameratesForCamera('ALEXA 35'),
    availableCodecs: getCodecsForCameraAndResolution('ALEXA 35', '4.6K 3:2 Open Gate (4608 x 3164)'),
    
    // Dailies specific state
    footagePerDay: "04:00:00:00",
    shootDays: 1,
    durationInput: "04:00:00:00",
    fileSizeInput: "1.91 TB",
    fileSizeUnit: "TB",
    dailiesFileSizePerDay: "43.949 GB",
    totalDailiesFileSize: "43.949 GB",
    selectedRatio: "Match",
    width: 1920,
    height: 1080,
    dailiesCodec: "Apple ProRes LT",
    lockedField: null,
    isWidthLocked: false,
    isHeightLocked: false,
    customRatios: [],
    dailiesData: {
      aspectRatio: 'Match',
      width: 1920,
      height: 1080,
      codec: 'Apple ProRes LT',
      fileSize: ''
    },
    
    // UI state
    isDurationLocked: false,
    isFileSizeLocked: false,
    calculationMode: null,
    copyStatus: '',
    isMobile: false,
    copiedLogIndex: null,
    previousResolution: '4.6K 3:2 Open Gate (4608 x 3164)'
  });

  // Duration to File Size state
  const [durationCalcState, setDurationCalcState] = useState({
    duration: "01:00:00:00",
    resolution: "UHD (3840 x 2160)",
    codec: "Apple ProRes 422 HQ",
    framerate: "23.976",
    fileSize: "310.328 GB",
    durationInput: "01:00:00:00",
    fileSizeInput: "310.328 GB",
    isDurationLocked: false,
    isFileSizeLocked: false,
    calculationMode: null
  });

  // Film Calculator state
  const [filmCalcState, setFilmCalcState] = useState({
    filmFormat: "35mm 4-perf",
    fps: "24",
    stock: "5219 VISION3 500T Color Neg",
    rollsCount: "1",
    rollLength: "1000",
    duration: "11 minutes 6.7 seconds",
    frames: "16,000",
    price: "$791.40",
    logButtonText: null,
    shareButtonText: null,
    resetButtonText: null,
    copyButtonText: null
  });

  // Transfer calculator state
  const [transferCalcState, setTransferCalcState] = useState({
    interface: 'Thunderbolt 4/USB4',
    storage: 'PCIe 4.0 NVMe SSD',
    dataSize: '1',
    sizeUnit: 'GB',
    lockedField: null
  });

  const resetCalculator = () => {
    setCameraCalcState(defaultCalcState);
  };

  const resetFramingChart = () => {
    setFramingChartState(defaultFramingChartState);
  };

  const value = {
    cameraCalcState,
    setCameraCalcState,
    durationCalcState,
    setDurationCalcState,
    aspectCalcState,
    setAspectCalcState,
    dailiesState,
    setDailiesState,
    filmCalcState,
    setFilmCalcState,
    bitrateCalcState,
    setBitrateCalcState,
    transferCalcState,
    setTransferCalcState,
    framingChartState,
    setFramingChartState,
    activeTab,
    setActiveTab,
    resetCalculator,
    resetFramingChart
  };

  return (
    <CalculatorContext.Provider value={value}>
      {children}
    </CalculatorContext.Provider>
  );
};

export const useCalculator = () => {
  const context = useContext(CalculatorContext);
  if (!context) {
    throw new Error('useCalculator must be used within a CalculatorProvider');
  }
  return context;
};
