// framerates: [".75", "1", "20", "23.976", "24", "25", "29.97", "30", "35", "38", "40", "41", "45", "48", "50", "55", "59.94", "60", "65", "70", "72", "75", "80", "90", "95", "96", "100", "105", "120"]},
// framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},


export const RedCameras = {
    "V-RAPTOR XL": {
        brand: "RED",
        resolutions: {
            "8K 17:9 (8192 x 4320)": {
                dimensions: [8192, 4320],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]}
                }
            }, 
            "8K 2.4:1 (8192 x 3456)": {
                dimensions: [8192, 3456],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "150"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "150"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "150"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "150"]}
                }
            },
            "7K 17:9 (7168 x 3780)": {
                dimensions: [7168, 3780],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "140"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "140"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "140"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "140"]}
                }
            },
            "7K 2.4:1 (7168 x 3024)": {
                dimensions: [7168, 3024],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "175"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "175"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "175"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "175"]}
                }
            },
            "6K 17:9 (6144 x 3240)": {
                dimensions: [6144, 3240],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "160"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "160"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "160"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "160"]}
                }
            },
            "6K 2.4:1 (6144 x 2592)": {
                dimensions: [6144, 2592],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "200"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "200"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "200"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "200"]}
                }
            },
            "5K 17:9 (5120 x 2700)": {
                dimensions: [5120, 2700],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "192"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "192"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "192"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "192"]}
                }
            },       
            "5K 2.4:1 (5120 x 2160)": {
                dimensions: [5120, 2160],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"]}
                }
            },
            "4K 17:9 (4096 x 2160)": {
                dimensions: [4096, 2160],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"]}
                }
            },       
            "4K 2.4:1 (4096 x 1728)": {
                dimensions: [4096, 1728],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "300"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "300"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "300"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "300"]}
                }
            },
            "3K 17:9 (3072 x 1620)": {
                dimensions: [3072, 1620],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "320"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "320"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "320"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "320"]}
                }
            },
            "3K 2.4:1 (3072 x 1296)": {
                dimensions: [3072, 1296],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "400"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "400"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "400"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "400"]}
                }
            },
            "2K 17:9 (2048 x 1080)": {
                dimensions: [2048, 1080],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "480"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "480"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "480"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "480"]}
                }
            },
            "2K 2.4:1 (2048 x 864)": {
                dimensions: [2048, 864],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "600"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "600"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "600"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "600"]}
                }
            },            
        }
    },            

    "KOMODO-X 6K": {
        brand: "RED",
        resolutions: {
            "6K 17:9 (6144 x 3240)": {
                dimensions: [6144, 3240],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "80"]}
                }
            }, 
            "5K 17:9 (5120 x 2700)": {
                dimensions: [5120, 2700],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "96"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "96"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "96"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "96"]}
                }
            },  
            "4K 17:9 (4096 x 2160)": {
                dimensions: [4096, 2160],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]}
                }
            },  
            "2K 17:9 (2048 x 1080)": {
                dimensions: [2048, 1080],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "240"]}
                }
            },            
        }
    },
    "KOMODO 6K": {
        brand: "RED",
        resolutions: {
            "6K 17:9 (6144 x 3240)": {
                dimensions: [6144, 3240],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "40"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "40"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "40"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "40"]}
                }
            }, 
            "5K 17:9 (5120 x 2700)": {
                dimensions: [5120, 2700],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "48"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "48"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "48"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "48"]}
                }
            },  
            "4K 17:9 (4096 x 2160)": {
                dimensions: [4096, 2160],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60"]}
                }
            },  
            "2K 17:9 (2048 x 1080)": {
                dimensions: [2048, 1080],
                codecs: {
                    "REDCODE HQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "REDCODE MQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "REDCODE LQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]},
                    "REDCODE ELQ": {framerates: ["23.976", "24", "25", "29.97", "30", "50", "59.94", "60", "120"]}
                }
            },            
        }
    },
   "DSMC2 DRAGON-X": {
        brand: "RED",
        resolutions: {
            "5K FF (5120 x 2700)": {
                dimensions: [5120,2700],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]}
                    }
                },
           "5K 2.4:1 (5120 x 2160)": {
                dimensions: [5120,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                    }
                },
           "5K 2:1 (5120 x 2560)": {
                dimensions: [5120,2560],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]}
                    }
                },
           "5K 16:9 (4800 x 2700)": {
                dimensions: [4800,2700],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]}
                    }
                },
           "4K FF(4096 x 2160)": {
                dimensions: [4096,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                    }
                },
           "4K 2.4:1 (4096 x 1728)": {
                dimensions: [4096,1728],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]}
                    }
                },
           "4K 2:1 (4096 x 2048)": {
                dimensions: [4096,2048],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                    }
                },
           "UHD (3840 x 2160)": {
                dimensions: [3840,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                    }
                },
           "2K FF(2048 x 1080)": {
                dimensions: [2048,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                    }
                },
           "2K 2.4:1 (2048 x 864)": {
                dimensions: [2048,864],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]}
                    }
                },
           "2K 2:1 (2048 x 1024)": {
                dimensions: [2048,1024],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                    }
                },
           "HD (1920 x 1080)": {
                dimensions: [1920,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                }
            }
        }
    },
    "DSMC2 HELIUM": {
        brand: "RED",
        resolutions: {
            "8K FF (8192 x 4320)": {
                dimensions: [8192,4320],
                codecs: {
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    // "REDCODE 6:1": {framerates: ["12"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]}
                    }
                },
           "8K 2.4:1 (8192 x 3456)": {
                dimensions: [8192,3456],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                    }
                },
           "8K 2:1 (8192 x 4096)": {
                dimensions: [8192,4096],
                codecs: {
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    // "REDCODE 6:1": {framerates: ["12"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]}
                    }
                },
           "8K 16:9 (7680 x 4320)": {
                dimensions: [7680,4320],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]}
                    }
                },
           "6K FF(6144 x 3240)": {
                dimensions: [6144,3240],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                    }
                },
           "6K 2.4:1 (6144 x 2592)": {
                dimensions: [6144,2592],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]}
                    }
                },
           "6K 2:1 (6144 x 3072)": {
                dimensions: [6144,3072],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                    }
                },
           "6K 16:9 (5760 x 3240)": {
                dimensions: [5760,3240],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                    }
                },
           "5K FF(5120 x 2700)": {
                dimensions: [5120,2700],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]}
                    }
                },
           "5K 2.4:1 (5120 x 2160)": {
                dimensions: [5120,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                    }
                },
           "5K 2:1 (5120 x 2560)": {
                dimensions: [5120,2560],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]}
                    }
                },
           "5K 16:9 (4800 x 2700)": {
                dimensions: [4800,2700],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]}
                    }
                },
           "4K FF(4096 x 2160)": {
                dimensions: [4096,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                    }
                },
           "4K 2.4:1 (4096 x 1728)": {
                dimensions: [4096,1728],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]}
                    }
                },
           "4K 2:1 (4096 x 2048)": {
                dimensions: [4096,2048],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                    }
                },
           "UHD (3840 x 2160)": {
                dimensions: [3840,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                    }
                },
           "2K FF(2048 x 1080)": {
                dimensions: [2048,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                    }
                },
           "2K 2.4:1 (2048 x 864)": {
                dimensions: [2048,864],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]}
                    }
                },
           "2K 2:1 (2048 x 1024)": {
                dimensions: [2048,1024],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                }
            },
            "HD (1920 x 1080)": {
                dimensions: [1920,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                }
            }
        }
    },
    "DSMC2 MONSTRO": {
        brand: "RED",
        resolutions: {
            "8K FF (8192 x 4320)": {
                dimensions: [8192,4320],
                codecs: {
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    // "REDCODE 6:1": {framerates: ["12"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]}
                }
            },
            "8K 2.4:1 (8192 x 3456)": {
                dimensions: [8192,3456],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                }
            },
            "8K 2:1 (8192 x 4096)": {
                dimensions: [8192,4096],
                codecs: {
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    // "REDCODE 6:1": {framerates: ["12"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]}
                }
            },
            "8K 16:9 (7680 x 4320)": {
                dimensions: [7680,4320],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]}
                }
            },
            "6K FF(6144 x 3240)": {
                dimensions: [6144,3240],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                }
            },
            "6K 2.4:1 (6144 x 2592)": {
                dimensions: [6144,2592],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]}
                }
            },
            "6K 2:1 (6144 x 3072)": {
                dimensions: [6144,3072],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                }
            },
            "6K 16:9 (5760 x 3240)": {
                dimensions: [5760,3240],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                }
            },
            "5K FF(5120 x 2700)": {
                dimensions: [5120,2700],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]}
                }
            },
            "5K 2.4:1 (5120 x 2160)": {
                dimensions: [5120,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                }
            },
            "5K 2:1 (5120 x 2560)": {
                dimensions: [5120,2560],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]}
                }
            },
            "5K 16:9 (4800 x 2700)": {
                dimensions: [4800,2700],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]}
                }
            },
            "4K FF(4096 x 2160)": {
                dimensions: [4096,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                }
            },
            "4K 2.4:1 (4096 x 1728)": {
                dimensions: [4096,1728],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]}
                }
            },
            "4K 2:1 (4096 x 2048)": {
                dimensions: [4096,2048],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                }
            },
            "UHD (3840 x 2160)": {
                dimensions: [3840,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                }
            },
            "2K FF(2048 x 1080)": {
                dimensions: [2048,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                }
            },
            "2K 2.4:1 (2048 x 864)": {
                dimensions: [2048,864],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]}
                            }
                        },
            "2K 2:1 (2048 x 1024)": {
                dimensions: [2048,1024],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                            }
                        },
            "HD (1920 x 1080)": {
                dimensions: [1920,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                }
            }
        }
    },
    "EPIC DRAGON": {
        brand: "RED",
        resolutions: {
            "6K FF (6144 x 3160)": {
                dimensions: [6144,3160],
                codecs: {
                    // "REDCODE 6:1": {framerates: ["12"]},
                    // "REDCODE 7:1": {framerates: ["12"]},
                    // "REDCODE 8:1": {framerates: ["12"]},
                    // "REDCODE 9:1": {framerates: ["12"]},
                    // "REDCODE 10:1": {framerates: ["12"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "60"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "60"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "60", "72"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "6K 2.4:1 (6144 x 2592)": {
                dimensions: [6144,2592],
                codecs: {
                    // "REDCODE 5:1": {framerates: ["12"]},
                    // "REDCODE 6:1": {framerates: ["12"]},
                    // "REDCODE 7:1": {framerates: ["12"]},
                    // "REDCODE 8:1": {framerates: ["12"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "72", "75", "90"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "72", "75", "90", "96", "100"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "72", "75", "90", "96", "100"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "72", "75", "90", "96", "100"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "72", "75", "90", "96", "100"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "72", "75", "90", "96", "100"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]}
                            }
                        },
            "6K 2:1 (6144 x 3072)": {
                dimensions: [6144,3072],
                codecs: {
                    // "REDCODE 6:1": {framerates: ["12"]},
                    // "REDCODE 7:1": {framerates: ["12"]},
                    // "REDCODE 8:1": {framerates: ["12"]},
                    // "REDCODE 9:1": {framerates: ["12"]},
                    // "REDCODE 10:1": {framerates: ["12"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "60"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "60"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "60"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "6K 16:9 (5760 x 3240)": {
                dimensions: [5760,3240],
                codecs: {
                    // "REDCODE 5:1": {framerates: ["12"]},
                    // "REDCODE 6:1": {framerates: ["12"]},
                    // "REDCODE 7:1": {framerates: ["12"]},
                    // "REDCODE 8:1": {framerates: ["12"]},
                    // "REDCODE 9:1": {framerates: ["12"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "60"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "60"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "5K FF(5120 x 2700)": {
                dimensions: [5120,2700],
                codecs: {
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    // "REDCODE 6:1": {framerates: ["12"]},
                    // "REDCODE 7:1": {framerates: ["12"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "72", "75", "90"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "72", "75", "90", "96"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "72", "75", "90", "96"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "72", "75", "90", "96"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "72", "75", "90", "96"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]}
                            }
                        },
            "5K 2.4:1 (5120 x 2160)": {
                dimensions: [5120,2160],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "90"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "90", "96", "100"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "90", "96", "100"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "5K 2:1 (5120 x 2560)": {
                dimensions: [5120,2560],
                codecs: {
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    // "REDCODE 6:1": {framerates: ["12"]},
                    // "REDCODE 7:1": {framerates: ["12"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "75", "90"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "75", "90", "96", "100"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "75", "90", "96", "100"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "75", "90", "96", "100"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "75", "90", "96", "100"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "75", "90", "96", "100"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "75", "90", "96", "100"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "75", "90", "96", "100"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "75", "90", "96", "100"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]}
                            }
                        },
            "5K 16:9 (4800 x 2700)": {
                dimensions: [4800,2700],
                codecs: {
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    // "REDCODE 6:1": {framerates: ["12"]},
                    // "REDCODE 7:1": {framerates: ["12"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "90"]},
                    "REDCODE 14:1": {framerates: ["12","23.976", "24","25","29.97", "30","40","90","96"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "90", "96"]},  
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "90", "96"]},  
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "90", "96"]},  
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "90", "96"]},  
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "90", "96"]},  
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "90", "96"]},  
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "90", "96"]},  
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "90", "96"]}
                            }
                        },
            "4K FF(4096 x 2160)": {
                dimensions: [4096,2160],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "4K 2.4:1 (4096 x 1728)": {
                dimensions: [4096,1728],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "150"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "150"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "150"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "150"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "150"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "150"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "150"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "150"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]}
                            }
                        },
            "4K 2:1 (4096 x 2048)": {
                dimensions: [4096,2048],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "UHD (3840 x 2160)": {
                dimensions: [3840,2160],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "2K FF(2048 x 1080)": {
                dimensions: [2048,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                            }
                        },
            "2K 2.4:1 (2048 x 864)": {
                dimensions: [2048,864],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]}
                            }
                        },
            "2K 2:1 (2048 x 1024)": {
                dimensions: [2048,1024],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                            }
                        },
            "HD (1920 x 1080)": {
                dimensions: [1920,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                }
            }
        }
    },
    "EPIC MYSTERIUM-X": {
        brand: "RED",
        resolutions: {
            "5K FF (5120 x 2700)": {
                dimensions: [5120,2700],
                codecs: {
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    // "REDCODE 6:1": {framerates: ["12"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "72"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "72", "75"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "72", "75", "90"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "72", "75", "90"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "72", "75", "90"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "72", "75", "90"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]}
                            }
                        },
            "5K 2.4:1 (5120 x 2160)": {
                dimensions: [5120,2160],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "90"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "90", "96", "100"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "90", "96", "100"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "5K 2:1 (5120 x 2560)": {
                dimensions: [5120,2560],
                codecs: {
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    // "REDCODE 6:1": {framerates: ["12"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "72", "75"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "72", "75", "90"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "72", "75", "90", "96", "100"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "72", "75", "90", "96", "100"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "72", "75", "90", "96", "100"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]}
                            }
                        },
            "5K 16:9 (4800 x 2700)": {
                dimensions: [4800,2700],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "75"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "75", "90"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "75", "90"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "75", "90"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "75", "90"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "75", "90"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "75", "90"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "75", "90"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]}
                            }
                        },
            "4K FF(4096 x 2160)": {
                dimensions: [4096,2160],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "4K 5K(4096 x 1728)": {
                dimensions: [4096,1728],
                codecs: {
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "4K 2.4:1 (4096 x 2048)": {
                dimensions: [4096,2048],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "150"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "150"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "150"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "150"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "150"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "150"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "150"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]}
                            }
                        },
            "UHD (3840 x 2160)": {
                dimensions: [3840,2160],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12",
                            "23.976", "24"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "2K FF(2048 x 1080)": {
                dimensions: [2048,1080],
                codecs: {
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]}
                            }
                        },
            "2K 5K(2048 x 864)": {
                dimensions: [2048,864],
                codecs: {
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]}
                            }
                        },
            "2K 2.4:1 (2048 x 1024)": {
                dimensions: [2048,1024],
                codecs: {
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]}
                            }
                        },
            "HD (1920 x 1080)": {
                dimensions: [1920,1080],
                codecs: {
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]}
                }
            }
        }
    },
    "EPIC-W 5K S35": {
        brand: "RED",
        resolutions: {
            "5K Full Height 6:5 (3600 x 3000)": {
                dimensions: [3600,3000],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "5K Full Height 1.7:1 (5120 x 3000)": {
                dimensions: [5120,3000],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "5K FF(5120 x 2700)": {
                dimensions: [5120,2700],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]}
                            }
                        },
            "5K 2.4:1 (5120 x 2160)": {
                dimensions: [5120,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "5K 2:1 (5120 x 2560)": {
                dimensions: [5120,2560],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]}
                            }
                        },
            "5K 16:9 (4800 x 2700)": {
                dimensions: [4800,2700],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]}
                            }
                        },
            "4K FF(4096 x 2160)": {
                dimensions: [4096,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "4K 2.4:1 (4096 x 1728)": {
                dimensions: [4096,1728],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]}
                            }
                        },
            "4K 2:1 (4096 x 2048)": {
                dimensions: [4096,2048],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "UHD (3840 x 2160)": {
                dimensions: [3840,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "2K FF(2048 x 1080)": {
                dimensions: [2048,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                            }
                        },
            "2K 2.4:1 (2048 x 864)": {
                dimensions: [2048,864],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]}
                            }
                        },
            "2K 2:1 (2048 x 1024)": {
                dimensions: [2048,1024],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                            }
                        },
            "HD (1920 x 1080)": {
                dimensions: [1920,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                }
            }
        }
    },
    "EPIC-W 8K S35": {
        brand: "RED",
        resolutions: {
            "8K FF (8192 x 4320)": {
                dimensions: [8192,4320],
                codecs: {
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    // "REDCODE 6:1": {framerates: ["12"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                      "REDCODE 22:1": {framerates: ["12", "23.976", "24","25","29.97", "30"]}
                            }
                        },
            "8K 2.4:1 (8192 x 3456)": {
                dimensions: [8192,3456],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                      "REDCODE 22:1": {framerates: ["12", "23.976", "24","25","29.97", "30"]}
                            }
                        },
            "8K 2:1 (8192 x 4096)": {
                dimensions: [8192,4096],
                codecs: {
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    // "REDCODE 6:1": {framerates: ["12"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                      "REDCODE 22:1": {framerates: ["12", "23.976", "24","25","29.97", "30"]}
                            }
                        },
            "8K 16:9 (7680 x 4320)": {
                dimensions: [7680,4320],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                      "REDCODE 22:1": {framerates: ["12", "23.976", "24","25","29.97", "30"]}
                            }
                        },
            "6K FF(6144 x 3160)": {
                dimensions: [6144,3160],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "6K 2.4:1 (6144 x 2592)": {
                dimensions: [6144,2592],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]}
                            }
                        },
            "6K 2:1 (6144 x 3072)": {
                dimensions: [6144,3072],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "6K 16:9 (5760 x 3240)": {
                dimensions: [5760,3240],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "5K FF(5120 x 2700)": {
                dimensions: [5120,2700],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]}
                            }
                        },
            "5K 2.4:1 (5120 x 2160)": {
                dimensions: [5120,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "5K 2:1 (5120 x 2560)": {
                dimensions: [5120,2560],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]}
                            }
                        },
            "5K 16:9 (4800 x 2700)": {
                dimensions: [4800,2700],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]}
                            }
                        },
            "4K FF(4096 x 2160)": {
                dimensions: [4096,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "4K 2.4:1 (4096 x 1728)": {
                dimensions: [4096,1728],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]}
                            }
                        },
            "4K 2:1 (4096 x 2048)": {
                dimensions: [4096,2048],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "UHD (3840 x 2160)": {
                dimensions: [3840,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "2K FF(2048 x 1080)": {
                dimensions: [2048,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                            }
                        },
            "2K 2.4:1 (2048 x 864)": {
                dimensions: [2048,864],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]}
                            }
                        },
            "2K 2:1 (2048 x 1024)": {
                dimensions: [2048,1024],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                            }
                        },
            "HD (1920 x 1080)": {
                dimensions: [1920,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                }
            }
        }
    },
    "RAVEN": {
        brand: "RED",
        resolutions: {
            "4K FF (4096 x 2160)": {
                dimensions: [4096,2160],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "4K 2.4:1 (4096 x 1728)": {
                dimensions: [4096,1728],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]}
                            }
                        },
            "4K 2:1 (4096 x 2048)": {
                dimensions: [4096,2048],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "UHD (3840 x 2160)": {
                dimensions: [3840,2160],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "4.5KFF (4608 x 2160)": {
                dimensions: [4608,2160],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "4.5K2.4:1 (4608 x 1944)": {
                dimensions: [4608,1944],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "2K FF(2048 x 1080)": {
                dimensions: [2048,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                            }
                        },
            "2K 2.4:1 (2048 x 864)": {
                dimensions: [2048,864],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]}
                            }
                        },
            "2K 2:1 (2048 x 1024)": {
                dimensions: [2048,1024],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                            }
                        },
            "HD (1920 x 1080)": {
                dimensions: [1920,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                }
            }
        }
    },
    "RANGER": {
        brand: "RED",
        resolutions: {
            "8K FF (8192 x 4320)": {
                dimensions: [8192,4320],
                codecs: {
                    // "REDCODE 4:1": {framerates: ["12"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]}
                            }
                        },
            "8K 2.4:1 (8192 x 3456)": {
                dimensions: [8192,3456],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "8K 2:1 (8192 x 4096)": {
                dimensions: [8192,4096],
                codecs: {
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    // "REDCODE 6:1": {framerates: ["12"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]}
                            }
                        },
            "8K 16:9 (7680 x 4320)": {
                dimensions: [7680,4320],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]}
                            }
                        },
            "6K FF(6144 x 3240)": {
                dimensions: [6144,3240],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "6K 2.4:1 (6144 x 2592)": {
                dimensions: [6144,2592],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]}
                            }
                        },
            "6K 2:1 (6144 x 3072)": {
                dimensions: [6144,3072],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "6K 16:9 (5760 x 3240)": {
                dimensions: [5760,3240],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "5K FF(5120 x 2700)": {
                dimensions: [5120,2700],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]}
                            }
                        },
            "5K 2.4:1 (5120 x 2160)": {
                dimensions: [5120,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "5K 2:1 (5120 x 2560)": {
                dimensions: [5120,2560],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]}
                            }
                        },
            "5K 16:9 (4800 x 2700)": {
                dimensions: [4800,2700],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]}
                            }
                        },
            "4K FF(4096 x 2160)": {
                dimensions: [4096,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "4K 2.4:1 (4096 x 1728)": {
                dimensions: [4096,1728],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]}
                            }
                        },
            "4K 2:1 (4096 x 2048)": {
                dimensions: [4096,2048],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "UHD (3840 x 2160)": {
                dimensions: [3840,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "2K FF(2048 x 1080)": {
                dimensions: [2048,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                            }
                        },
            "2K 2.4:1 (2048 x 864)": {
                dimensions: [2048,864],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]}
                            }
                        },
            "2K 2:1 (2048 x 1024)": {
                dimensions: [2048,1024],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                            }
                        },
            "HD (1920 x 1080)": {
                dimensions: [1920,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                }
            }
        }
    },
    "SCARLET MYSTERIUM-X": {
        brand: "RED",
        resolutions: {
            // "5K FF (5120 x 2700)": {
            //     dimensions: [5120,2700],
            //     codecs: {
            //         // "REDCODE 5:1": {framerates: ["12"]},
            //         // "REDCODE 6:1": {framerates: ["12"]},
            //         // "REDCODE 7:1": {framerates: ["12"]},
            //         // "REDCODE 8:1": {framerates: ["12"]},
            //         // "REDCODE 9:1": {framerates: ["12"]},
            //         // "REDCODE 10:1": {framerates: ["12"]},
            //         // "REDCODE 11:1": {framerates: ["12"]},
            //         // "REDCODE 12:1": {framerates: ["12"]},
            //         // "REDCODE 13:1": {framerates: ["12"]},
            //         // "REDCODE 14:1": {framerates: ["12"]},
            //         // "REDCODE 15:1": {framerates: ["12"]},
            //         // "REDCODE 16:1": {framerates: ["12"]},
            //         // "REDCODE 17:1": {framerates: ["12"]},
            //         // "REDCODE 18:1": {framerates: ["12"]},
            //         // "REDCODE 19:1": {framerates: ["12"]},
            //         // "REDCODE 20:1": {framerates: ["12"]},
            //         // "REDCODE 21:1": {framerates: ["12"]},
            //         // "REDCODE 22:1": {framerates: ["12"]}
            //     }
            // },
            // "5K 2.4:1 (5120 x 2160)": {
            //     dimensions: [5120,2160],
            //     codecs: {
            //         // "REDCODE 4:1": {framerates: ["12"]},
            //         // "REDCODE 5:1": {framerates: ["12"]},
            //         // "REDCODE 6:1": {framerates: ["12"]},
            //         // "REDCODE 7:1": {framerates: ["12"]},
            //         // "REDCODE 8:1": {framerates: ["12"]},
            //         // "REDCODE 9:1": {framerates: ["12"]},
            //         // "REDCODE 10:1": {framerates: ["12"]},
            //         // "REDCODE 11:1": {framerates: ["12"]},
            //         // "REDCODE 12:1": {framerates: ["12"]},
            //         // "REDCODE 13:1": {framerates: ["12"]},
            //         // "REDCODE 14:1": {framerates: ["12"]},
            //         // "REDCODE 15:1": {framerates: ["12"]},
            //         // "REDCODE 16:1": {framerates: ["12"]},
            //         // "REDCODE 17:1": {framerates: ["12"]},
            //         // "REDCODE 18:1": {framerates: ["12"]},
            //         // "REDCODE 19:1": {framerates: ["12"]},
            //         // "REDCODE 20:1": {framerates: ["12"]},
            //         // "REDCODE 21:1": {framerates: ["12"]},
            //         // "REDCODE 22:1": {framerates: ["12"]}
            //     }
            // },
            // "5K 2:1 (5120 x 2560)": {
            //     dimensions: [5120,2560],
            //     codecs: {
            //         // "REDCODE 5:1": {framerates: ["12"]},
            //         // "REDCODE 6:1": {framerates: ["12"]},
            //         // "REDCODE 7:1": {framerates: ["12"]},
            //         // "REDCODE 8:1": {framerates: ["12"]},
            //         // "REDCODE 9:1": {framerates: ["12"]},
            //         // "REDCODE 10:1": {framerates: ["12"]},
            //         // "REDCODE 11:1": {framerates: ["12"]},
            //         // "REDCODE 12:1": {framerates: ["12"]},
            //         // "REDCODE 13:1": {framerates: ["12"]},
            //         // "REDCODE 14:1": {framerates: ["12"]},
            //         // "REDCODE 15:1": {framerates: ["12"]},
            //         // "REDCODE 16:1": {framerates: ["12"]},
            //         // "REDCODE 17:1": {framerates: ["12"]},
            //         // "REDCODE 18:1": {framerates: ["12"]},
            //         // "REDCODE 19:1": {framerates: ["12"]},
            //         // "REDCODE 20:1": {framerates: ["12"]},
            //         // "REDCODE 21:1": {framerates: ["12"]},
            //         // "REDCODE 22:1": {framerates: ["12"]}
            //     }
            // },
            // "5K 16:9 (4800 x 2700)": {
            //     dimensions: [4800,2700],
            //     codecs: {
            //         // "REDCODE 5:1": {framerates: ["12"]},
            //         // "REDCODE 6:1": {framerates: ["12"]},
            //         // "REDCODE 7:1": {framerates: ["12"]},
            //         // "REDCODE 8:1": {framerates: ["12"]},
            //         // "REDCODE 9:1": {framerates: ["12"]},
            //         // "REDCODE 10:1": {framerates: ["12"]},
            //         // "REDCODE 11:1": {framerates: ["12"]},
            //         // "REDCODE 12:1": {framerates: ["12"]},
            //         // "REDCODE 13:1": {framerates: ["12"]},
            //         // "REDCODE 14:1": {framerates: ["12"]},
            //         // "REDCODE 15:1": {framerates: ["12"]},
            //         // "REDCODE 16:1": {framerates: ["12"]},
            //         // "REDCODE 17:1": {framerates: ["12"]},
            //         // "REDCODE 18:1": {framerates: ["12"]},
            //         // "REDCODE 19:1": {framerates: ["12"]},
            //         // "REDCODE 20:1": {framerates: ["12"]},
            //         // "REDCODE 21:1": {framerates: ["12"]},
            //         // "REDCODE 22:1": {framerates: ["12"]}
            //     }
            // },
            "4K FF(4096 x 2160)": {
                dimensions: [4096,2160],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25"]}
                            }
                        },
            "4K 2.4:1 (4096 x 1728)": {
                dimensions: [4096,1728],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25"]}
                            }
                        },
            "UHD (4096 x 2048)": {
                dimensions: [4096,2048],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25"]}
                            }
                        },
            "2K FF(3840 x 2160)": {
                dimensions: [3840,2160],
                codecs: {
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "40", "50"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]}
                            }
                        },
            "2K 2.4:1 (2048 x 1080)": {
                dimensions: [2048,1080],
                codecs: {
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60", "72", "75"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60", "72", "75"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60", "72", "75"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60", "72", "75"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60", "72", "75"]}
                            }
                        },
            "HD (2048 x 864)": {
                dimensions: [2048,864],
                codecs: {
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "40", "50"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "40", "50", "60"]}
                }
            }
        }
    },
    "SCARLET-W": {
        brand: "RED",
        resolutions: {
            "5K FF (5120 x 2700)": {
                dimensions: [5120,2700],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]}
                            }
                        },
            "5K 2.4:1 (5120 x 2160)": {
                dimensions: [5120,2160],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]}
                            }
                        },
            "5K 2:1 (5120 x 2560)": {
                dimensions: [5120,2560],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]}
                            }
                        },
            "5K 16:9 (4800 x 2700)": {
                dimensions: [4800,2700],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]}
                            }
                        },
            "4K FF(4096 x 2160)": {
                dimensions: [4096,2160],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "4K 2.4:1 (4096 x 1728)": {
                dimensions: [4096,1728],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]}
                            }
                        },
            "4K 2:1 (4096 x 2048)": {
                dimensions: [4096,2048],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "UHD (3840 x 2160)": {
                dimensions: [3840,2160],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "2K FF(2048 x 1080)": {
                dimensions: [2048,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                            }
                        },
            "2K 2.4:1 (2048 x 864)": {
                dimensions: [2048,864],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]}
                            }
                        },
            "2K 2:1 (2048 x 1024)": {
                dimensions: [2048,1024],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                            }
                        },
            "HD (1920 x 1080)": {
                dimensions: [1920,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                }
            }
        }
    },
    "SCARLET-X DRAGON": {
        brand: "RED",
        resolutions: {
            // "6K FF (6144 x 3160)": {
            //     dimensions: [6144,3160],
            //     codecs: {
            //         // "REDCODE 6:1": {framerates: ["12"]},
            //         // "REDCODE 7:1": {framerates: ["12"]},
            //         // "REDCODE 8:1": {framerates: ["12"]},
            //         // "REDCODE 9:1": {framerates: ["12"]},
            //         // "REDCODE 10:1": {framerates: ["12"]},
            //         // "REDCODE 11:1": {framerates: ["12"]},
            //         // "REDCODE 12:1": {framerates: ["12"]},
            //         // "REDCODE 13:1": {framerates: ["12"]},
            //         // "REDCODE 14:1": {framerates: ["12"]},
            //         // "REDCODE 15:1": {framerates: ["12"]},
            //         // "REDCODE 16:1": {framerates: ["12"]},
            //         // "REDCODE 17:1": {framerates: ["12"]},
            //         // "REDCODE 18:1": {framerates: ["12"]},
            //         // "REDCODE 19:1": {framerates: ["12"]},
            //         // "REDCODE 20:1": {framerates: ["12"]},
            //         // "REDCODE 21:1": {framerates: ["12"]},
            //         // "REDCODE 22:1": {framerates: ["12"]}
            //     }
            // },
            // "6K 16:9 (5760 x 3240)": {
            //     dimensions: [5760,3240],
            //     codecs: {
            //         // "REDCODE 5:1": {framerates: ["12"]},
            //         // "REDCODE 6:1": {framerates: ["12"]},
            //         // "REDCODE 7:1": {framerates: ["12"]},
            //         // "REDCODE 8:1": {framerates: ["12"]},
            //         // "REDCODE 9:1": {framerates: ["12"]},
            //         // "REDCODE 10:1": {framerates: ["12"]},
            //         // "REDCODE 11:1": {framerates: ["12"]},
            //         // "REDCODE 12:1": {framerates: ["12"]},
            //         // "REDCODE 13:1": {framerates: ["12"]},
            //         // "REDCODE 14:1": {framerates: ["12"]},
            //         // "REDCODE 15:1": {framerates: ["12"]},
            //         // "REDCODE 16:1": {framerates: ["12"]},
            //         // "REDCODE 17:1": {framerates: ["12"]},
            //         // "REDCODE 18:1": {framerates: ["12"]},
            //         // "REDCODE 19:1": {framerates: ["12"]},
            //         // "REDCODE 20:1": {framerates: ["12"]},
            //         // "REDCODE 21:1": {framerates: ["12"]},
            //         // "REDCODE 22:1": {framerates: ["12"]}
            //     }
            // },
            "5K FF(5120 x 2700)": {
                dimensions: [5120,2700],
                codecs: {
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    // "REDCODE 6:1": {framerates: ["12"]},
                    // "REDCODE 7:1": {framerates: ["12"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]}
                            }
                        },
            "5K 2.4:1 (5120 x 2160)": {
                dimensions: [5120,2160],
                codecs: {
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    // "REDCODE 6:1": {framerates: ["12"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]}
                            }
                        },
            "5K 2:1 (5120 x 2560)": {
                dimensions: [5120,2560],
                codecs: {
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    // "REDCODE 6:1": {framerates: ["12"]},
                    // "REDCODE 7:1": {framerates: ["12"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]}
                            }
                        },
            "5K 16:9 (4800 x 2700)": {
                dimensions: [4800,2700],
                codecs: {
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    // "REDCODE 6:1": {framerates: ["12"]},
                    // "REDCODE 7:1": {framerates: ["12"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]}
                            }
                        },
            "4K FF(4096 x 2160)": {
                dimensions: [4096,2160],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]}
                            }
                        },
            "4K 2.4:1 (4096 x 1728)": {
                dimensions: [4096,1728],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12",
                            "23.976", "24"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "4K 2:1 (4096 x 2048)": {
                dimensions: [4096,2048],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]}
                            }
                        },
            "UHD (3840 x 2160)": {
                dimensions: [3840,2160],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]}
                            }
                        },
            "2K FF(2048 x 1080)": {
                dimensions: [2048,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "2K 2.4:1 (2048 x 864)": {
                dimensions: [2048,864],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]}
                            }
                        },
            "2K 2:1 (2048 x 1024)": {
                dimensions: [2048,1024],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "HD (1920 x 1080)": {
                dimensions: [1920,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                }
            }
        }
    },
    "WEAPON 6K (CARBON FIBER)": {
        brand: "RED",
        resolutions: {
            "6K FF (6144 x 3160)": {
                dimensions: [6144,3160],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "6K 2.4:1 (6144 x 2592)": {
                dimensions: [6144,2592],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]}
                            }
                        },
            "6K 2:1 (6144 x 3072)": {
                dimensions: [6144,3072],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "6K 16:9 (5760 x 3240)": {
                dimensions: [5760,3240],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "5K FF(5120 x 2700)": {
                dimensions: [5120,2700],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]}
                            }
                        },
            "5K 2.4:1 (5120 x 2160)": {
                dimensions: [5120,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "5K 2:1 (5120 x 2560)": {
                dimensions: [5120,2560],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]}
                            }
                        },
            "5K 16:9 (4800 x 2700)": {
                dimensions: [4800,2700],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]}
                            }
                        },
            "4K FF(4096 x 2160)": {
                dimensions: [4096,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "4K 2.4:1 (4096 x 1728)": {
                dimensions: [4096,1728],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]}
                            }
                        },
            "4K 2:1 (4096 x 2048)": {
                dimensions: [4096,2048],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "UHD (3840 x 2160)": {
                dimensions: [3840,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "2K FF(2048 x 1080)": {
                dimensions: [2048,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                            }
                        },
            "2K 2.4:1 (2048 x 864)": {
                dimensions: [2048,864],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]}
                            }
                        },
            "2K 2:1 (2048 x 1024)": {
                dimensions: [2048,1024],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                            }
                        },
            "HD (1920 x 1080)": {
                dimensions: [1920,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                }
            }
        }
    },
    "WEAPON 6K (MAGNESIUM)": {
        brand: "RED",
        resolutions: {
            "6K FF (6144 x 3160)": {
                dimensions: [6144,3160],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "6K 2.4:1 (6144 x 2592)": {
                dimensions: [6144,2592],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]}
                            }
                        },
            "6K 2:1 (6144 x 3072)": {
                dimensions: [6144,3072],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "6K 16:9 (5760 x 3240)": {
                dimensions: [5760,3240],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "5K   6:5 (3600 x 3000)": {
                dimensions: [3600,3000],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "5K   1.7:1 (5120 x 3000)": {
                dimensions: [5120,3000],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "5K FF(5120 x 2700)": {
                dimensions: [5120,2700],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]}
                            }
                        },
            "5K 2.4:1 (5120 x 2160)": {
                dimensions: [5120,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "5K 2:1 (5120 x 2560)": {
                dimensions: [5120,2560],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]}
                            }
                        },
            "5K 16:9 (4800 x 2700)": {
                dimensions: [4800,2700],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]}
                            }
                        },
            "4K FF(4096 x 2160)": {
                dimensions: [4096,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "4K 2.4:1 (4096 x 1728)": {
                dimensions: [4096,1728],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]}
                            }
                        },
            "4K 2:1 (4096 x 2048)": {
                dimensions: [4096,2048],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12",
                            "23.976", "24",
                            "25",
                            "29.97", "30",
                            "40",
                            "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "UHD (3840 x 2160)": {
                dimensions: [3840,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "2K FF(2048 x 1080)": {
                dimensions: [2048,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                            }
                        },
            "2K 2.4:1 (2048 x 864)": {
                dimensions: [2048,864],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]}
                            }
                        },
            "2K 2:1 (2048 x 1024)": {
                dimensions: [2048,1024],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                            }
                        },
            "HD (1920 x 1080)": {
                dimensions: [1920,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                }
            }
        }
    },
    "WEAPON 8K S35": {
        brand: "RED",
        resolutions: {
            "8K FF (8192 x 4320)": {
                dimensions: [8192,4320],
                codecs: {
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    // "REDCODE 6:1": {framerates: ["12"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]}
                            }
                        },
            "8K 2.4:1 (8192 x 3456)": {
                dimensions: [8192,3456],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "8K 2:1 (8192 x 4096)": {
                dimensions: [8192,4096],
                codecs: {
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    // "REDCODE 6:1": {framerates: ["12"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]}
                            }
                        },
            "8K 16:9 (7680 x 4320)": {
                dimensions: [7680,4320],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]}
                            }
                        },
            "6K FF(6144 x 3240)": {
                dimensions: [6144,3240],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "6K 2.4:1 (6144 x 2592)": {
                dimensions: [6144,2592],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]}
                            }
                        },
            "6K 2:1 (6144 x 3072)": {
                dimensions: [6144,3072],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "6K 16:9 (5760 x 3240)": {
                dimensions: [5760,3240],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "5K FF(5120 x 2700)": {
                dimensions: [5120,2700],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]}
                            }
                        },
            "5K 2.4:1 (5120 x 2160)": {
                dimensions: [5120,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "5K 2:1 (5120 x 2560)": {
                dimensions: [5120,2560],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]}
                            }
                        },
            "5K 16:9 (4800 x 2700)": {
                dimensions: [4800,2700],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]}
                            }
                        },
            "4K FF(4096 x 2160)": {
                dimensions: [4096,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "4K 2.4:1 (4096 x 1728)": {
                dimensions: [4096,1728],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]}
                            }
                        },
            "4K 2:1 (4096 x 2048)": {
                dimensions: [4096,2048],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "UHD (3840 x 2160)": {
                dimensions: [3840,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "2K FF(2048 x 1080)": {
                dimensions: [2048,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                            }
                        },
            "2K 2.4:1 (2048 x 864)": {
                dimensions: [2048,864],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]}
                            }
                        },
            "2K 2:1 (2048 x 1024)": {
                dimensions: [2048,1024],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                            }
                        },
            "HD (1920 x 1080)": {
                dimensions: [1920,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                }
            }
        }
    },
    "WEAPON MONSTRO 8K VV": {
        brand: "RED",
        resolutions: {
            "8K FF (8192 x 4320)": {
                dimensions: [8192,4320],
                codecs: {
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    // "REDCODE 6:1": {framerates: ["12"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]}
                            }
                        },
            "8K 2.4:1 (8192 x 3456)": {
                dimensions: [8192,3456],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "8K 2:1 (8192 x 4096)": {
                dimensions: [8192,4096],
                codecs: {
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    // "REDCODE 6:1": {framerates: ["12"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]}
                            }
                        },
            "8K 16:9 (7680 x 4320)": {
                dimensions: [7680,4320],
                codecs: {
                    // "REDCODE 3:1": {framerates: ["12"]},
                    // "REDCODE 4:1": {framerates: ["12"]},
                    // "REDCODE 5:1": {framerates: ["12"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]}
                            }
                        },
            "6K FF(6144 x 3240)": {
                dimensions: [6144,3240],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "6K 2.4:1 (6144 x 2592)": {
                dimensions: [6144,2592],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]}
                            }
                        },
            "6K 2:1 (6144 x 3072)": {
                dimensions: [6144,3072],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "6K 16:9 (5760 x 3240)": {
                dimensions: [5760,3240],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    // "REDCODE 3:1": {framerates: ["12"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]}
                            }
                        },
            "5K FF(5120 x 2700)": {
                dimensions: [5120,2700],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]}
                            }
                        },
            "5K 2.4:1 (5120 x 2160)": {
                dimensions: [5120,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "5K 2:1 (5120 x 2560)": {
                dimensions: [5120,2560],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]}
                            }
                        },
            "5K 16:9 (4800 x 2700)": {
                dimensions: [4800,2700],
                codecs: {
                    // "REDCODE 2:1": {framerates: ["12"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]}
                            }
                        },
            "4K FF(4096 x 2160)": {
                dimensions: [4096,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "4K 2.4:1 (4096 x 1728)": {
                dimensions: [4096,1728],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]}
                            }
                        },
            "4K 2:1 (4096 x 2048)": {
                dimensions: [4096,2048],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "UHD (3840 x 2160)": {
                dimensions: [3840,2160],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]}
                            }
                        },
            "2K FF(2048 x 1080)": {
                dimensions: [2048,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                            }
                        },
            "2K 2.4:1 (2048 x 864)": {
                dimensions: [2048,864],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240", "288", "300"]}
                            }
                        },
            "2K 2:1 (2048 x 1024)": {
                dimensions: [2048,1024],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]}
                            }
                        },
            "HD (1920 x 1080)": {
                dimensions: [1920,1080],
                codecs: {
                    "REDCODE 2:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120"]},
                    "REDCODE 3:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175"]},
                    "REDCODE 4:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200"]},
                    "REDCODE 5:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 6:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 7:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 8:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 9:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 10:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 11:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 12:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 13:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 14:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 15:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 16:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 17:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 18:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 19:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 20:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 21:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"]},
                    "REDCODE 22:1": {framerates: ["12", "23.976", "24", "25", "29.97", "30", "40", "50", "60", "72", "75", "90", "96", "100", "120", "150", "175", "200", "240"
                        ]
                    }
                }
            }
        }
    }
     
    // ... other cameras ...
};







