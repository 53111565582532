// TBD Post's DataCalc WebApp
// datacalc@tbdpost.com

import React, { useState, useRef, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import AspectRatioCalculator from './components/AspectRatioCalculator';
import BitrateCalculator from './components/BitrateCalculator';
import CameraToFileSize from './components/CameraToFileSize';
import ComingSoon from './components/ComingSoon';
import DailiesCalculator from './components/DailiesCalculator';
import DurationToFileSize from './components/DurationToFileSize';
import FileNameCreator from './components/FileNameCreator';
import FilmCalculator from './components/FilmCalculator';
import TransferTimeCalculator from './components/TransferTimeCalculator';
import Log from './components/Log';
import SideMenu from './components/SideMenu';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import './App.css';
import { CalculatorProvider } from './context/CalculatorContext';
import { deviceTypes, breakpoints, spacing } from './styles/globalStyles';
import { calculatorConfig, getCalculatorHeaderName, isInBetaEnvironment } from './config/features';

// Add this helper function to detect if we're in staging
const isStaging = () => {
  return isInBetaEnvironment();
};

// Add this helper function at the top of your file
const getBasename = () => {
  // If we're on beta.data-calc.com, don't use a basename
  if (window.location.hostname === 'beta.data-calc.com') {
    return '/';
  }
  // If we're in beta environment but not on beta subdomain (like localhost)
  if (isInBetaEnvironment()) {
    return '/beta';
  }
  // Production environment
  return '/';
};

// Add this helper function
const getRoutePath = (path) => {
  const basename = getBasename();
  if (basename === '/') {
    return path;
  }
  return `${basename}${path}`;
};

function App() {
  const [deviceState, setDeviceState] = useState({
    isMobile: deviceTypes.isMobile(),
    isTablet: deviceTypes.isTablet(),
    isTouchDevice: deviceTypes.isTouchDevice(),
    isWideScreen: window.innerWidth >= breakpoints.desktop,
    isLandscape: window.innerWidth > window.innerHeight
  });
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [logs, setLogs] = useState(() => {
    const savedLogs = localStorage.getItem('calculatorLogs');
    return savedLogs ? JSON.parse(savedLogs) : [];
  });
  const logRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [previousPath, setPreviousPath] = useState('/duration');

  // Get current page info
  const currentPageInfo = Object.values(calculatorConfig).find(
    calc => calc.path === location.pathname
  ) || calculatorConfig.duration;


  useEffect(() => {
    const handleResize = () => {
      setDeviceState({
        isMobile: deviceTypes.isMobile(),
        isTablet: deviceTypes.isTablet(),
        isTouchDevice: deviceTypes.isTouchDevice(),
        isWideScreen: window.innerWidth >= breakpoints.desktop,
        isLandscape: window.innerWidth > window.innerHeight
      });
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const addLog = (entry) => {
    setLogs(prevLogs => [entry, ...prevLogs]);
  };

  const deleteLog = (index) => {
    setLogs(prevLogs => prevLogs.filter((_, i) => i !== index));
  };

  const handleShare = (log) => {
    if (navigator.share) {
      navigator.share({
        title: 'DataCalc Log Entry',
        text: log
      }).catch(console.error);
    }
  };
  
  useEffect(() => {
    localStorage.setItem('calculatorLogs', JSON.stringify(logs));
  }, [logs]);

  useEffect(() => {
    if (location.pathname !== '/logs') {
      setPreviousPath(location.pathname);
    }
  }, [location.pathname]);

  return (
    <CalculatorProvider>
      <div className={`app min-h-screen flex flex-col ${
        deviceState.isMobile ? 'pt-1' : 'pt-2'
      } overflow-hidden`}>
        <div className="fixed top-0 left-0 right-0 z-10">
          <Header 
            isMobile={deviceState.isMobile}
            isTablet={deviceState.isTablet}
          />
        </div>

        <div className={`flex-grow w-full ${
          deviceState.isMobile ? spacing.mobile.contentPadding :
          spacing.desktop.contentPadding
        } mt-20 mb-20 bg-gray-100 overflow-hidden`}>
          <div className={`max-w-[1920px] mx-auto flex ${deviceState.isWideScreen ? 'space-x-4' : 'flex-col'}`}>
            {/* Side Menu - only show on desktop */}
            {(deviceState.isWideScreen && !deviceState.isTablet) || 
             (deviceState.isTablet && window.innerWidth > window.innerHeight) ? (
              <div className="flex-shrink-0">
                <div className="sticky top">
                  <SideMenu isMobile={false} />
                </div>
              </div>
            ) : null}

            {/* Main Content Area */}
            <div className={`flex ${
              deviceState.isWideScreen || deviceState.isTablet ? 'flex-row space-x-4' : 'flex-col space-y-1'
            } w-full`}>
              {/* Main calculator area */}
              <div className={`flex flex-col flex-grow ${
                deviceState.isTablet ? 'basis-2/3' : 'basis-3/5'
              } ${deviceState.isMobile ? '' : 'space-y-2'}`}>
                {!deviceState.isMobile && (
                  <div className="p-4 text-center border-b border-gray-200 bg-white rounded shadow-md">
                    <h1 className="text-2xl font-bold mb-4">{currentPageInfo.title}</h1>
                    <p className="text-sm text-gray-600">{currentPageInfo.description}</p>
                  </div>
                )}
                
                <div className={`${
                  location.pathname === '/logs' 
                    ? "p-2" 
                    : deviceState.isMobile 
                      ? "p-1.5 shadow-md rounded bg-white text-center" 
                      : "p-3 shadow-md rounded bg-white text-center"
                } `}>
                  <Routes>
                    <Route path="/" element={<Navigate to={`${getBasename()}/duration`.replace('//', '/')} replace />} />
                    <Route path="/duration" element={<DurationToFileSize addLog={addLog} />} />
                    <Route path="/beta/duration" element={<DurationToFileSize addLog={addLog} />} />
                    <Route path="/aspect-ratio" element={<AspectRatioCalculator addLog={addLog} />} />
                    <Route path="/beta/aspect-ratio" element={<AspectRatioCalculator addLog={addLog} />} />
                    <Route path="/bitrate" element={<BitrateCalculator addLog={addLog} />} />
                    <Route path="/beta/bitrate" element={<BitrateCalculator addLog={addLog} />} />
                    <Route path="/bitrate-calculator" element={<BitrateCalculator addLog={addLog} />} />
                    <Route path="/dailies" element={<DailiesCalculator addLog={addLog} />} />
                    <Route path="/dailies-calculator" element={<DailiesCalculator addLog={addLog} />} />
                    <Route path="/dailies-to-file-size" element={<DailiesCalculator addLog={addLog} />} />
                    <Route path="/dailies-to-file-size-calculator" element={<DailiesCalculator addLog={addLog} />} />
                    <Route path="/file-size-calculator" element={<DurationToFileSize addLog={addLog} />} />
                    <Route path="/video-duration-to-file-size-calculator" element={<DurationToFileSize addLog={addLog} />} />
                    <Route path="/camera" element={<CameraToFileSize addLog={addLog} />} />
                    <Route path="/camera-to-file-size-calculator" element={<CameraToFileSize addLog={addLog} />} />
                    <Route path="/filename" element={<FileNameCreator addLog={addLog} />} />
                    <Route path="/filename-creator" element={<FileNameCreator addLog={addLog} />} />
                    <Route path="/film" element={<FilmCalculator addLog={addLog} />} />
                    <Route path="/film-calculator" element={<FilmCalculator addLog={addLog} />} />
                    <Route path="/motion-picture-film" element={<FilmCalculator addLog={addLog} />} />
                    <Route path="/motion-picture-film-calculator" element={<FilmCalculator addLog={addLog} />} />
                    <Route path="/transfer-time" element={<TransferTimeCalculator addLog={addLog} />} />
                    <Route path="/transfer-time-calculator" element={<TransferTimeCalculator addLog={addLog} />} />
                    <Route path="/comingsoon" element={<ComingSoon />} />
                    <Route path="/logs" element={
                      <div className={`flex flex-col ${deviceState.isTablet ? 'max-w-2xl mx-auto' : ''}`}>
                        {deviceState.isMobile && (
                          <div className="sticky mb-2">
                            <button onClick={() => navigate(-1)} className="w-full flex justify-center py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 shadow-md">
                              <span>Back</span>
                            </button>
                          </div>
                        )}
                        <Log 
                          log={logs} 
                          onDelete={deleteLog} 
                          onShare={handleShare} 
                          isMobile={deviceState.isMobile} 
                          isFullPage={true}
                          previousCalculator={previousPath}
                        />
                      </div>
                    } />
                  </Routes>
                </div>
              </div>

              {/* Right side log area */}
              <div className={`${
                deviceState.isMobile ? 'h-[calc(100vh-180px)]' : 'mb-20'
              } flex flex-col ${
                deviceState.isTablet ? 'w-1/3' : 
                window.innerWidth <= 1024 ? 'w-full' : 'w-[40%]'
              } ${location.pathname === '/logs' ? 'invisible' : ''}`}>
                <div 
                  className={`${deviceState.isMobile ? 'p-1 m-1' : 'p-2 mb-2'} text-center bg-white rounded shadow-md flex-shrink-0`}
                  onClick={() => deviceState.isMobile && navigate('/logs', { state: { from: location.pathname } })}
                  style={{ cursor: deviceState.isMobile ? 'pointer' : 'default' }}
                >
                  <h2 className={`${deviceState.isMobile ? 'text-md' : 'text-lg'} font-semibold text-gray-700`}>
                    Log {deviceState.isMobile && '(tap to view all)'}
                  </h2>
                </div>
                <div className={`flex-1 notes ${deviceState.isMobile ? 'overflow-hidden' : 'overflow-visible'}`}>
                  <div className={`${deviceState.isMobile ? 'h-full' : ''}`}>
                    <Log 
                      log={logs} 
                      onDelete={deleteLog} 
                      onShare={handleShare} 
                      isMobile={deviceState.isMobile} 
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="fixed bottom-0 left-0 right-0">
          <Footer 
            setShowPrivacyPolicy={setShowPrivacyPolicy} 
            isMobile={deviceState.isMobile}
          />
        </div>

        {showPrivacyPolicy && (
          <PrivacyPolicy onClose={() => setShowPrivacyPolicy(false)} />
        )}
      </div>
    </CalculatorProvider>
  );
}

export default App;
