import React, { useState, useEffect, useRef, useCallback } from 'react';
import { SlRefresh, SlLike, SlNote, SlPaperPlane } from "react-icons/sl";
import { LuDownload } from "react-icons/lu";
import { saveAs } from 'file-saver';
import CalculatorButtons from './CalculatorButtons';
import { colors, spacing, commonStyles, layout } from '../styles/globalStyles';
import { useCalculator } from '../context/CalculatorContext';
import { getCamerasByBrand, getResolutionsForCamera, getCodecsForCameraAndResolution } from '../data/cameraData';
import { brands } from '../data/brandData';

// Update chartColors to include all element colors
const chartColors = {
  // Background
  background: '#000000',      // Black background

  // Full Frame Elements
  fullFrame: {
    triangles: '#E0E0E0',    // Light gray
    labels: '#FFFFFF',        // White
  },

  // Safety Crop Elements
  safetyCrop: {
    area: '#A0A0A0',         // Medium gray for safety crop area
    triangles: '#990000',    // Deep red for triangles
    corners: '#990000',      // Deep red for corners
    labels: '#990000',       // Deep red for labels
  },

  // Aspect Ratio Elements
  aspectRatio: {
    area: '#404040',         // Dark gray for outside aspect
    triangles: {
      outline: '#808080',    // Dark gray outline
      fill: '#A0A0A0',       // Medium gray fill
    },
    labels: '#FFFFFF',       // White
  },

  // Overlays
  overlays: {
    uhd: {
      border: 'rgba(128, 128, 128, 0.4)',     // More subtle gray for border
      corners: '#FF0000',                      // Keep red corners
      label: 'rgba(128, 128, 128, 0.6)',      // More subtle gray for label
    },
    // siemensStars: '#FFFFFF', // White
    siemensStars: '#000000', // Black
    cornerCircles: {
      stroke: '#808080',     // Dark gray
      text: '#808080',       // Dark gray
    },
    crosshair: '#FF0000',    // Red
  },

  // Information Text
  info: {
    text: '#000000',         // Black
  },

  // Watermark
  watermark: 'rgba(255, 255, 255, 0.03)', // Very subtle white
};

// Add this utility function at the top of the file
const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

// Add this helper function near the top of the file
const isResolutionBelowUHD = (resolution) => {
  const dimensions = resolution.match(/\((\d+)\s*x\s*(\d+)\)/);
  if (dimensions) {
    const width = parseInt(dimensions[1]);
    const height = parseInt(dimensions[2]);
    return width <= 3840 && height <= 2160;
  }
  return false;
};

const FramingChartGenerator = ({ addLog }) => {
  const { 
    framingChartState, 
    setFramingChartState, 
    cameraCalcState 
  } = useCalculator();
  
  // State for camera selection
  const [availableCameras, setAvailableCameras] = useState([]);
  const [availableResolutions, setAvailableResolutions] = useState([]);
  
  // Refs for wheel events
  const canvasRef = useRef(null);
  const brandRef = useRef(null);
  const cameraRef = useRef(null);
  const resolutionRef = useRef(null);
  
  // Button states
  const [downloadButtonText, setDownloadButtonText] = useState(<LuDownload />);
  const [shareButtonText, setShareButtonText] = useState(<SlPaperPlane />);
  const [resetButtonText, setResetButtonText] = useState(<SlRefresh />);

  // Add new state for view toggle
  const [showSqueezed, setShowSqueezed] = useState(false);

  // Add state for codecs
  const [availableCodecs, setAvailableCodecs] = useState([]);

  // Add state for Siemens star image
  const [siemensStarImage, setSiemensStarImage] = useState(null);

  // Add state for element visibility
  const [chartElements, setChartElements] = useState({
    fullFrameLabel: true,
    fullFrameTriangles: true,
    safetyCropLabel: true,
    safetyCropTriangles: true,
    safetyCropCorners: true,
    aspectRatioLabel: true,
    aspectRatioTriangles: true,
    siemensStars: true,
    cornerCircles: true,
    uhdOverlay: true,
    infoText: true,
    crosshair: true
  });

  // Add state for element groups
  const [elementGroups, setElementGroups] = useState({
    fullFrame: true,
    safetyCrop: true,
    aspectRatio: true,
    overlays: true,
    info: true
  });

  // Add to state declarations at the top of the component
  const [customRatio, setCustomRatio] = useState('');
  const [customRatios, setCustomRatios] = useState([]);

  // Add this state to track if we're in custom input mode
  const [isCustomInput, setIsCustomInput] = useState(false);

  // Update initial state to default to spherical
  const defaultState = {
    brand: 'Sony',
    camera: 'VENICE',
    resolution: '6K 17:9 (6504 x 3192)',
    showName: 'SHOW NAME',
    dopName: 'DOP NAME',
    safetyMargin: 5,
    showUhdOverlay: true,
    aspectRatio: '16:9',
    isAnamorphic: false,
    squeezeRatio: '1x (Spherical)',
  };

  const [isMobile, setIsMobile] = useState(cameraCalcState.isMobile);

  // Move drawChart definition before the useEffect
  const drawChart = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas || !framingChartState.resolution) {
      return;
    }

    // Parse resolution from the selected camera resolution string
    const resolutionMatch = framingChartState.resolution.match(/\((\d+)\s*x\s*(\d+)\)/);
    if (!resolutionMatch) {
      console.error('Could not parse resolution:', framingChartState.resolution);
      return;
    }

    const [, width, height] = resolutionMatch;
    const canvasWidth = parseInt(width);
    const canvasHeight = parseInt(height);

    // Set canvas to native resolution of the camera
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    // Calculate preview size while maintaining aspect ratio
    const container = canvas.parentElement;
    const containerWidth = container.clientWidth;
    
    // Calculate scale based on width
    const scaleByWidth = containerWidth / canvasWidth;
    const heightAtScale = canvasHeight * scaleByWidth;

    // Set canvas display size
    canvas.style.width = `${containerWidth}px`;
    canvas.style.height = `${heightAtScale}px`;
    
    // Update container min-height to match canvas
    canvas.parentElement.style.minHeight = 'auto';
    canvas.parentElement.style.height = `${heightAtScale}px`;

    // Get squeeze ratio for anamorphic lenses
    const squeezeRatio = parseFloat(framingChartState.lensSqueeze);
    const isAnamorphic = squeezeRatio !== 1;
    const shouldApplySqueeze = isAnamorphic && showSqueezed;

    // Draw the chart
    const ctx = canvas.getContext('2d');
    drawSingleChart(ctx, canvasWidth, canvasHeight, 1, shouldApplySqueeze);
  }, [
    framingChartState.resolution,
    framingChartState.lensSqueeze,
    showSqueezed,
    chartElements,
    siemensStarImage,
    framingChartState
  ]);

  // Update the Siemens star loading useEffect
  useEffect(() => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = '/Siemens_star.svg';
    img.onload = () => {
      setSiemensStarImage(img);
      if (framingChartState.resolution) {
        drawChart();
      }
    };
    img.onerror = (error) => {
      console.error('Error loading Siemens star image:', error);
    };
  }, [drawChart]);

  // Update available cameras when brand changes
  useEffect(() => {
    const cameras = getCamerasByBrand(framingChartState.brand);
    setAvailableCameras(cameras);
    
    if (!cameras.includes(framingChartState.camera)) {
      const newCamera = cameras[0] || '';
      setFramingChartState(prev => ({ 
        ...prev, 
        camera: newCamera,
        resolution: '' // Reset resolution
      }));
    }
  }, [framingChartState.brand]);

  // Update available resolutions when camera changes
  useEffect(() => {
    if (framingChartState.camera) {
      try {
        const resolutions = getResolutionsForCamera(framingChartState.camera);
        setAvailableResolutions(resolutions);
        
        if (!resolutions.includes(framingChartState.resolution)) {
          const newResolution = resolutions[0] || '';
          setFramingChartState(prev => ({ 
            ...prev, 
            resolution: newResolution
          }));
        }
      } catch (error) {
        console.error('Error getting resolutions:', error);
        setAvailableResolutions([]);
      }
    }
  }, [framingChartState.camera]);

  // Update useEffect for codecs when resolution changes
  useEffect(() => {
    if (framingChartState.camera && framingChartState.resolution) {
      try {
        const codecs = getCodecsForCameraAndResolution(framingChartState.camera, framingChartState.resolution);
        setAvailableCodecs(codecs);
        
        if (!codecs.includes(framingChartState.codec)) {
          const newCodec = codecs[0] || '';
          setFramingChartState(prev => ({ 
            ...prev, 
            codec: newCodec
          }));
        }
      } catch (error) {
        console.error('Error getting codecs:', error);
        setAvailableCodecs([]);
      }
    }
  }, [framingChartState.camera, framingChartState.resolution]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // List of parameters that should trigger a redraw
    const redrawParams = [
      'brand', 'camera', 'resolution', 'aspectRatio', 
      'safetyMargin', 'lensSqueeze', 'showUhdOverlay'
    ];
    
    if (redrawParams.includes(name)) {
      setFramingChartState(prevData => ({
        ...prevData,
        [name]: name === 'safetyMargin' ? Number(value) : value
      }));
      
      // Reset dependent fields if necessary
      if (name === 'brand') {
        setFramingChartState(prevData => ({
          ...prevData,
          camera: '',
          resolution: ''
        }));
      } else if (name === 'camera') {
        setFramingChartState(prevData => ({
          ...prevData,
          resolution: ''
        }));
      }
    } else {
      // Handle other inputs that don't need immediate redraw
      setFramingChartState(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  // Wheel event handlers
  const handleWheel = (e, options, currentValue, setValue) => {
    e.preventDefault();
    const delta = e.deltaY > 0 ? 1 : -1;
    const currentIndex = options.indexOf(currentValue);
    let newIndex = currentIndex + delta;
    
    // Prevent wrapping around
    if (newIndex < 0) newIndex = 0;
    if (newIndex >= options.length) newIndex = options.length - 1;
    
    setValue(options[newIndex]);
  };

  const handleBrandWheel = (e) => {
    handleWheel(
      e,
      brands,
      framingChartState.brand,
      (newBrand) => {
        setFramingChartState(prev => ({ ...prev, brand: newBrand }));
      }
    );
  };

  const handleCameraWheel = (e) => {
    handleWheel(
      e,
      availableCameras,
      framingChartState.camera,
      (newCamera) => {
        setFramingChartState(prev => ({ ...prev, camera: newCamera }));
      }
    );
  };

  const handleResolutionWheel = (e) => {
    handleWheel(
      e,
      availableResolutions,
      framingChartState.resolution,
      (newResolution) => {
        setFramingChartState(prev => ({ ...prev, resolution: newResolution }));
      }
    );
  };

  // Touch event handlers
  const handleBrandTouch = (e) => {
    e.preventDefault();
    const select = e.target;
    const currentIndex = select.selectedIndex;
    const options = Array.from(select.options);
    
    let newIndex = currentIndex + 1;
    if (newIndex >= options.length) newIndex = options.length - 1;
    
    setFramingChartState(prev => ({ ...prev, brand: options[newIndex].value }));
  };

  const handleCameraTouch = (e) => {
    e.preventDefault();
    const select = e.target;
    const currentIndex = select.selectedIndex;
    const options = Array.from(select.options);
    
    let newIndex = currentIndex + 1;
    if (newIndex >= options.length) newIndex = options.length - 1;
    
    setFramingChartState(prev => ({ ...prev, camera: options[newIndex].value }));
  };

  const handleResolutionTouch = (e) => {
    e.preventDefault();
    const select = e.target;
    const currentIndex = select.selectedIndex;
    const options = Array.from(select.options);
    
    let newIndex = currentIndex + 1;
    if (newIndex >= options.length) newIndex = options.length - 1;
    
    setFramingChartState(prev => ({ ...prev, resolution: options[newIndex].value }));
  };

  // Add wheel event listeners
  useEffect(() => {
    const elements = [
      { ref: brandRef, handler: handleBrandWheel },
      { ref: cameraRef, handler: handleCameraWheel },
      { ref: resolutionRef, handler: handleResolutionWheel }
    ];

    elements.forEach(({ ref, handler }) => {
      if (ref.current) {
        ref.current.addEventListener('wheel', handler, { passive: false });
      }
    });

    return () => {
      elements.forEach(({ ref, handler }) => {
        if (ref.current) {
          ref.current.removeEventListener('wheel', handler);
        }
      });
    };
  }, [framingChartState.brand, framingChartState.camera, framingChartState.resolution]);

  // Update calculateResolutions function to calculate safety margin within aspect ratio
  const calculateResolutions = (fullWidth, fullHeight, safetyMargin, aspectRatio) => {
    // First calculate aspect ratio dimensions
    let aspectWidth, aspectHeight;
    const [ratioWidth, ratioHeight] = aspectRatio.split(':').map(Number);
    const targetRatio = ratioWidth / ratioHeight;
    
    if (fullWidth / fullHeight > targetRatio) {
      aspectHeight = fullHeight;
      aspectWidth = Math.round(aspectHeight * targetRatio);
    } else {
      aspectWidth = fullWidth;
      aspectHeight = Math.round(aspectWidth / targetRatio);
    }

    // Ensure even numbers for aspect and safety dimensions
    aspectWidth = Math.floor(aspectWidth / 2) * 2;
    aspectHeight = Math.floor(aspectHeight / 2) * 2;

    const safetyScale = 1 - (safetyMargin / 100);
    let safetyCropWidth = Math.round(aspectWidth * safetyScale);
    let safetyCropHeight = Math.round(aspectHeight * safetyScale);
    
    // Ensure safety dimensions are even
    safetyCropWidth = Math.floor(safetyCropWidth / 2) * 2;
    safetyCropHeight = Math.floor(safetyCropHeight / 2) * 2;

    return {
      full: { width: fullWidth, height: fullHeight },
      aspect: { width: aspectWidth, height: aspectHeight },
      safety: { width: safetyCropWidth, height: safetyCropHeight }
    };
  };

  // Update drawTriangleMarker to handle outline color and width
  const drawTriangleMarker = (ctx, x, y, direction, color, isDashed = false, fillColor = null, outlineColor = null) => {
    const size = Math.min(ctx.canvas.width, ctx.canvas.height) * 0.02;
    
    ctx.beginPath();
    ctx.strokeStyle = outlineColor || color;
    ctx.fillStyle = fillColor || color;
    ctx.lineWidth = isDashed ? 4 : 5;
    
    if (isDashed) {
      ctx.setLineDash([10, 10]);
    }

    // Increased offset for better alignment with edges
    const offset = isDashed ? 3 : 2;
    
    switch(direction) {
      case 'left':
        ctx.moveTo(x + offset, y);
        ctx.lineTo(x + size + offset, y - size/2);
        ctx.lineTo(x + size + offset, y + size/2);
        break;
      case 'right':
        ctx.moveTo(x - offset, y);
        ctx.lineTo(x - size - offset, y - size/2);
        ctx.lineTo(x - size - offset, y + size/2);
        break;
      case 'top':
        ctx.moveTo(x, y + offset);
        ctx.lineTo(x - size/2, y + size + offset);
        ctx.lineTo(x + size/2, y + size + offset);
        break;
      case 'bottom':
        ctx.moveTo(x, y - offset);
        ctx.lineTo(x - size/2, y - size - offset);
        ctx.lineTo(x + size/2, y - size - offset);
        break;
    }

    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    if (isDashed) {
      ctx.setLineDash([]);
    }
  };

  // Update drawTriangleMarkers to handle outline color
  const drawTriangleMarkers = (ctx, x1, y1, x2, y2, color, isDashed = false, fillColor = null, direction = null, outlineColor = null, side = null) => {
    // Calculate positions for three triangles
    const positions = [];
    
    // If vertical line
    if (x1 === x2) {
      const height = y2 - y1;
      // Remove the horizontal offset for triangles
      positions.push(
        { x: x1, y: y1 + height * 0.5, direction: direction || (x1 === 0 ? 'left' : 'right') },  // Center
        { x: x1, y: y1 + height * 0.167, direction: direction || (x1 === 0 ? 'left' : 'right') }, // Top third
        { x: x1, y: y1 + height * 0.833, direction: direction || (x1 === 0 ? 'left' : 'right') }  // Bottom third
      );
    } 
    // If horizontal line
    else {
      const width = x2 - x1;
      positions.push(
        { x: x1 + width * 0.5, y: y1, direction: direction || (y1 === 0 ? 'top' : 'bottom') },   // Center
        { x: x1 + width * 0.167, y: y1, direction: direction || (y1 === 0 ? 'top' : 'bottom') },  // Left third
        { x: x1 + width * 0.833, y: y1, direction: direction || (y1 === 0 ? 'top' : 'bottom') }   // Right third
      );
    }

    // Draw triangles at calculated positions
    positions.forEach(pos => {
      drawTriangleMarker(ctx, pos.x, pos.y, pos.direction, color, isDashed, fillColor, outlineColor);
    });
  };

  // Update the drawLabeledBorder function to remove the white border
  const drawLabeledBorder = (ctx, x, y, width, height, label, color, fontSize, labelPosition = 'below', isDashed = false) => {
    // Only draw the label, remove the border drawing code
    ctx.font = `${fontSize}px monospace`;
    ctx.fillStyle = color;
    ctx.textAlign = 'center';
    ctx.textBaseline = labelPosition === 'below' ? 'top' : 'bottom';
    const labelY = labelPosition === 'below' ? y + height + 5 : y - 5;
    ctx.fillText(label, x + width/2, labelY);
  };

  // Update the drawCrosshair function to remove checkmark
  const drawCrosshair = (ctx, centerX, centerY) => {
    const size = Math.min(ctx.canvas.width, ctx.canvas.height) * 0.05;
    const thickness = 5;
    
    ctx.save();
    ctx.strokeStyle = chartColors.overlays.crosshair;
    ctx.lineWidth = thickness;
    
    // Horizontal line
    ctx.beginPath();
    ctx.moveTo(centerX - size, centerY);
    ctx.lineTo(centerX + size, centerY);
    ctx.stroke();
    
    // Vertical line
    ctx.beginPath();
    ctx.moveTo(centerX, centerY - size);
    ctx.lineTo(centerX, centerY + size);
    ctx.stroke();
    
    ctx.restore();
  };

  // Add function to draw resolution labels
  const drawResolutionLabels = (ctx, canvasWidth, canvasHeight, resolutionName, dimensions, fontSize) => {
    ctx.save();
    ctx.fillStyle = chartColors.fullFrame.labels;
    ctx.font = `${fontSize}px monospace`;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    // Calculate positions based on triangle spacing
    const centerY = canvasHeight * 0.5;
    const topThirdY = canvasHeight * 0.167;
    const bottomThirdY = canvasHeight * 0.833;
    const edgePadding = fontSize; // Use fontSize as padding to ensure text fits

    // Left side labels (reading left to right from bottom to top)
    // Position between middle and bottom triangle
    ctx.save();
    ctx.translate(edgePadding, (centerY + bottomThirdY) / 2);
    ctx.rotate(-Math.PI / 2);
    ctx.fillText(resolutionName, 0, 0);
    ctx.restore();

    // Position between middle and top triangle
    ctx.save();
    ctx.translate(edgePadding, (centerY + topThirdY) / 2);
    ctx.rotate(-Math.PI / 2);
    ctx.fillText(dimensions, 0, 0);
    ctx.restore();

    // Right side labels (reading left to right from top to bottom)
    // Position between top and middle triangle
    ctx.save();
    ctx.translate(canvasWidth - edgePadding, (centerY + topThirdY) / 2);
    ctx.rotate(Math.PI / 2);
    ctx.fillText(resolutionName, 0, 0);
    ctx.restore();

    // Position between middle and bottom triangle
    ctx.save();
    ctx.translate(canvasWidth - edgePadding, (centerY + bottomThirdY) / 2);
    ctx.rotate(Math.PI / 2);
    ctx.fillText(dimensions, 0, 0);
    ctx.restore();

    ctx.restore();
  };

  // Add function to draw aspect ratio labels
  const drawAspectRatioLabels = (ctx, aspectX, aspectY, aspectWidth, aspectHeight, resolutionName, dimensions, fontSize) => {
    ctx.save();
    ctx.fillStyle = chartColors.aspectRatio.labels;
    ctx.font = `${fontSize}px monospace`;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    // Top label
    // Left side of center triangle
    ctx.fillText(resolutionName, 
      aspectX + aspectWidth * 0.25, 
      aspectY - fontSize);

    // Right side of center triangle
    ctx.fillText(dimensions, 
      aspectX + aspectWidth * 0.75, 
      aspectY - fontSize);

    // Bottom label
    // Left side of center triangle
    ctx.fillText(resolutionName, 
      aspectX + aspectWidth * 0.25, 
      aspectY + aspectHeight + fontSize);

    // Right side of center triangle
    ctx.fillText(dimensions, 
      aspectX + aspectWidth * 0.75, 
      aspectY + aspectHeight + fontSize);

    ctx.restore();
  };


  // Update drawSingleChart to ensure proper layer order
  const drawSingleChart = (ctx, canvasWidth, canvasHeight, scale, applySqueeze) => {
    // Define fontSize at the very beginning
    const fontSize = Math.min(canvasWidth, canvasHeight) * 0.02;

    // Only apply squeeze if we're viewing squeezed chart and have anamorphic lens
    const shouldApplySqueeze = applySqueeze && parseFloat(framingChartState.lensSqueeze) !== 1;
    const squeezeRatio = shouldApplySqueeze ? parseFloat(framingChartState.lensSqueeze) : 1;

    // Calculate circle dimensions early
    const circleRadius = Math.min(canvasWidth, canvasHeight) * 0.03;
    const circleInset = circleRadius * 1.667;

    // Draw background first
    ctx.fillStyle = chartColors.background;
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);

    // Calculate resolutions without squeeze for proper positioning
    const resolutions = calculateResolutions(
      canvasWidth,
      canvasHeight,
      framingChartState.safetyMargin,
      framingChartState.aspectRatio
    );

    // Calculate positions without squeeze
    const aspectX = (canvasWidth - resolutions.aspect.width) / 2;
    const aspectY = (canvasHeight - resolutions.aspect.height) / 2;
    const safeX = (canvasWidth - resolutions.safety.width) / 2;
    const safeY = (canvasHeight - resolutions.safety.height) / 2;



    // Fill areas from outside in
    ctx.fillStyle = chartColors.aspectRatio.area;
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);
    
    ctx.fillStyle = chartColors.safetyCrop.area;
    ctx.fillRect(aspectX, aspectY, resolutions.aspect.width, resolutions.aspect.height);
    
    ctx.fillStyle = chartColors.fullFrame.triangles;
    ctx.fillRect(safeX, safeY, resolutions.safety.width, resolutions.safety.height);

    ctx.font = `${fontSize * 4}px monospace`;
    ctx.fillStyle = '#D3D3D3';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'bottom';
    const watermarkY = canvasHeight - (canvasHeight * 0.12);
    ctx.fillText('data-calc.com', canvasWidth / 2, watermarkY);

    // Draw safety crop elements only if safety margin is not zero
    if (framingChartState.safetyMargin > 0) {
      const cornerLength = Math.min(resolutions.safety.width, resolutions.safety.height) * 0.075;
      ctx.strokeStyle = chartColors.safetyCrop.corners;
      ctx.lineWidth = 5;

      // Top-left corner
      ctx.beginPath();
      ctx.moveTo(safeX, safeY + cornerLength);
      ctx.lineTo(safeX, safeY);
      ctx.lineTo(safeX + cornerLength, safeY);
      ctx.stroke();

      // Top-right corner
      ctx.beginPath();
      ctx.moveTo(safeX + resolutions.safety.width - cornerLength, safeY);
      ctx.lineTo(safeX + resolutions.safety.width, safeY);
      ctx.lineTo(safeX + resolutions.safety.width, safeY + cornerLength);
      ctx.stroke();

      // Bottom-left corner
      ctx.beginPath();
      ctx.moveTo(safeX, safeY + resolutions.safety.height - cornerLength);
      ctx.lineTo(safeX, safeY + resolutions.safety.height);
      ctx.lineTo(safeX + cornerLength, safeY + resolutions.safety.height);
      ctx.stroke();

      // Bottom-right corner
      ctx.beginPath();
      ctx.moveTo(safeX + resolutions.safety.width - cornerLength, safeY + resolutions.safety.height);
      ctx.lineTo(safeX + resolutions.safety.width, safeY + resolutions.safety.height);
      ctx.lineTo(safeX + resolutions.safety.width, safeY + resolutions.safety.height - cornerLength);
      ctx.stroke();

      // Draw safety crop triangles
      const safetyColor = chartColors.safetyCrop.triangles;
      
      // Top edge - triangles pointing up
      drawTriangleMarkers(
        ctx,
        safeX,
        safeY,
        safeX + resolutions.safety.width,
        safeY,
        safetyColor,
        false,
        safetyColor,
        'top',
        safetyColor
      );
      
      // Bottom edge - triangles pointing down
      drawTriangleMarkers(
        ctx,
        safeX,
        safeY + resolutions.safety.height,
        safeX + resolutions.safety.width,
        safeY + resolutions.safety.height,
        safetyColor,
        false,
        safetyColor,
        'bottom',
        safetyColor
      );
      
      // Left edge - triangles pointing left
      drawTriangleMarkers(
        ctx,
        safeX,
        safeY,
        safeX,
        safeY + resolutions.safety.height,
        safetyColor,
        false,
        safetyColor,
        'left',
        safetyColor
      );
      
      // Right edge - triangles pointing right
      drawTriangleMarkers(
        ctx,
        safeX + resolutions.safety.width,
        safeY,
        safeX + resolutions.safety.width,
        safeY + resolutions.safety.height,
        safetyColor,
        false,
        safetyColor,
        'right',
        safetyColor
      );

      // Draw safety crop label inside the area
      if (chartElements.safetyCropLabel) {
        ctx.font = `${fontSize * 0.7}px monospace`; // Half the normal font size
        ctx.fillStyle = chartColors.safetyCrop.labels;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom'; // Change to 'bottom' to align with bottom edge
        
        // Position text at the bottom of the safety area
        const textX = safeX + resolutions.safety.width * 0.9330; // Position at 60% of width
        const textY = safeY + resolutions.safety.height - 2; // Position 2 pixels from bottom edge
        ctx.fillText(`Safety Crop - ${framingChartState.safetyMargin}%`, textX, textY);
      }
    }

    // Draw labeled borders with labels below
    if (chartElements.fullFrameLabel) {
      drawLabeledBorder(ctx, 0, 0, canvasWidth, canvasHeight, 'Full Frame', chartColors.fullFrame.labels, fontSize);
    }

    // Draw aspect ratio triangles
    if (chartElements.aspectRatioTriangles) {
      const triangleColor = chartColors.aspectRatio.triangles;
      
      // Top edge - triangles pointing up
      drawTriangleMarkers(
        ctx,
        aspectX,
        aspectY,
        aspectX + resolutions.aspect.width,
        aspectY,
        triangleColor.outline,
        true,
        triangleColor.fill,
        'top',
        triangleColor.outline
      );
      
      // Bottom edge - triangles pointing down
      drawTriangleMarkers(
        ctx,
        aspectX,
        aspectY + resolutions.aspect.height,
        aspectX + resolutions.aspect.width,
        aspectY + resolutions.aspect.height,
        triangleColor.outline,
        true,
        triangleColor.fill,
        'bottom',
        triangleColor.outline
      );
      
      // Left edge - triangles pointing left (but on right side of line)
      drawTriangleMarkers(
        ctx,
        aspectX,
        aspectY,
        aspectX,
        aspectY + resolutions.aspect.height,
        triangleColor.outline,
        true,
        triangleColor.fill,
        'left',  // Changed from 'right' to 'left' to point towards line
        triangleColor.outline,
        'right'  // New parameter to specify which side of the line
      );
      
      // Right edge - triangles pointing right (but on left side of line)
      drawTriangleMarkers(
        ctx,
        aspectX + resolutions.aspect.width,
        aspectY,
        aspectX + resolutions.aspect.width,
        aspectY + resolutions.aspect.height,
        triangleColor.outline,
        true,
        triangleColor.fill,
        'right',  // Changed from 'left' to 'right' to point towards line
        triangleColor.outline,
        'left'  // New parameter to specify which side of the line
      );
    }

    // Draw full frame triangles
    if (chartElements.fullFrameTriangles) {
      const triangleColor = chartColors.fullFrame.triangles;
      const outlineColor = '#808080'; // Middle gray outline
      
      // Draw full frame triangles with middle gray outline
      // Top edge
      drawTriangleMarkers(ctx, 0, 0, canvasWidth, 0, triangleColor, false, triangleColor, 'top', outlineColor);
      // Bottom edge
      drawTriangleMarkers(ctx, 0, canvasHeight, canvasWidth, canvasHeight, triangleColor, false, triangleColor, 'bottom', outlineColor);
      // Left edge
      drawTriangleMarkers(ctx, 0, 0, 0, canvasHeight, triangleColor, false, triangleColor, 'left', outlineColor);
      // Right edge
      drawTriangleMarkers(ctx, canvasWidth, 0, canvasWidth, canvasHeight, triangleColor, false, triangleColor, 'right', outlineColor);
    }

    // After drawing full frame triangles, add resolution labels
    if (chartElements.fullFrameLabel) {
      // Get resolution name without dimensions
      const resolutionName = framingChartState.resolution.split('(')[0].trim();
      // Get dimensions from resolution string
      const dimensions = framingChartState.resolution.match(/\(([^)]+)\)/)[1].trim();
      
      // Draw resolution labels on full frame
      ctx.fillStyle = chartColors.fullFrame.labels;
      ctx.font = `${fontSize}px monospace`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';

      // Calculate positions for labels with reduced padding
      const edgePadding = fontSize * 0.75; // Reduced from fontSize * 2
      const centerY = canvasHeight * 0.5;
      
      // Left side labels (rotated -90 degrees)
      ctx.save();
      // Resolution name between center and bottom triangle
      ctx.translate(edgePadding, centerY + (canvasHeight * 0.167));
      ctx.rotate(-Math.PI / 2);
      ctx.fillText(resolutionName, 0, 0);
      ctx.restore();

      ctx.save();
      // Resolution numbers between center and top triangle
      ctx.translate(edgePadding, centerY - (canvasHeight * 0.167));
      ctx.rotate(-Math.PI / 2);
      ctx.fillText(dimensions, 0, 0);
      ctx.restore();

      // Right side labels (rotated 90 degrees)
      ctx.save();
      // Resolution name between center and top triangle
      ctx.translate(canvasWidth - edgePadding, centerY - (canvasHeight * 0.167));
      ctx.rotate(Math.PI / 2);
      ctx.fillText(resolutionName, 0, 0);
      ctx.restore();

      ctx.save();
      // Resolution numbers between center and bottom triangle
      ctx.translate(canvasWidth - edgePadding, centerY + (canvasHeight * 0.167));
      ctx.rotate(Math.PI / 2);
      ctx.fillText(dimensions, 0, 0);
      ctx.restore();
    }

    // Draw info text
    if (chartElements.infoText) {
      // Calculate positions relative to center
      const centerY = canvasHeight * 0.5;
      const topInfoY = centerY - (canvasHeight * 0.125);
      const bottomInfoY = centerY + (canvasHeight * 0.125);
      
      ctx.fillStyle = chartColors.info.text;
      ctx.font = `${fontSize}px monospace`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      
      // Draw show and DoP info above center
      const topInfoLines = [
        framingChartState.showName ? `Show: ${framingChartState.showName}` : '',
        framingChartState.dopName ? `DoP: ${framingChartState.dopName}` : ''
      ].filter(Boolean);

      topInfoLines.forEach((line, index) => {
        ctx.fillText(line, canvasWidth/2, topInfoY + (index * fontSize * 1.5));
      });
      
      // Draw technical info below center (without codec)
      const bottomInfoLines = [
        `${framingChartState.brand} ${framingChartState.camera}`,
        `Sensor Mode: ${framingChartState.resolution}`,
        `Active Frame: ${Math.floor(resolutions.aspect.width / 2) * 2} x ${Math.floor(resolutions.aspect.height / 2) * 2}`,
        `Safety: ${framingChartState.safetyMargin}% - ${Math.floor((resolutions.aspect.width - ((framingChartState.safetyMargin / 100) * resolutions.aspect.width)) / 2) * 2} x ${Math.floor((resolutions.aspect.height - ((framingChartState.safetyMargin / 100) * resolutions.aspect.height)) / 2) * 2}`,
        `Aspect Ratio: ${framingChartState.aspectRatio}${parseFloat(framingChartState.lensSqueeze) !== 1 ? ` ${framingChartState.lensSqueeze} Anamorphic` : ' Spherical'}`
      ].filter(Boolean);

      bottomInfoLines.forEach((line, index) => {
        ctx.fillText(line, canvasWidth/2, bottomInfoY + (index * fontSize * 1.5));
      });
    }

    // First draw UHD overlay (move this section before Siemens stars)
    if (chartElements.uhdOverlay && framingChartState.showUhdOverlay) {
      // Calculate UHD overlay position
      const uhdWidth = 3840;
      const uhdHeight = 2160;
      const uhdX = (canvasWidth - uhdWidth) / 2;
      const uhdY = (canvasHeight - uhdHeight) / 2;

      // Draw dashed gray border
      ctx.strokeStyle = chartColors.overlays.uhd.border;
      ctx.setLineDash([40, 40]); // Large dash spacing
      ctx.lineWidth = 5;
      ctx.strokeRect(uhdX, uhdY, uhdWidth, uhdHeight);
      ctx.setLineDash([]);

      // Draw red corners
      const cornerLength = Math.min(uhdWidth, uhdHeight) * 0.075;
      ctx.strokeStyle = chartColors.overlays.uhd.corners;
      ctx.lineWidth = 5;

      // Top-left corner
      ctx.beginPath();
      ctx.moveTo(uhdX, uhdY + cornerLength);
      ctx.lineTo(uhdX, uhdY);
      ctx.lineTo(uhdX + cornerLength, uhdY);
      ctx.stroke();

      // Top-right corner
      ctx.beginPath();
      ctx.moveTo(uhdX + uhdWidth - cornerLength, uhdY);
      ctx.lineTo(uhdX + uhdWidth, uhdY);
      ctx.lineTo(uhdX + uhdWidth, uhdY + cornerLength);
      ctx.stroke();

      // Bottom-left corner
      ctx.beginPath();
      ctx.moveTo(uhdX, uhdY + uhdHeight - cornerLength);
      ctx.lineTo(uhdX, uhdY + uhdHeight);
      ctx.lineTo(uhdX + cornerLength, uhdY + uhdHeight);
      ctx.stroke();

      // Bottom-right corner
      ctx.beginPath();
      ctx.moveTo(uhdX + uhdWidth - cornerLength, uhdY + uhdHeight);
      ctx.lineTo(uhdX + uhdWidth, uhdY + uhdHeight);
      ctx.lineTo(uhdX + uhdWidth, uhdY + uhdHeight - cornerLength);
      ctx.stroke();

      // Draw label above the UHD overlay
      ctx.fillStyle = chartColors.overlays.uhd.label;
      ctx.font = `${fontSize/2}px monospace`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';
      ctx.fillText(
        'Maximum allowed crop for UHD delivery - 3840 x 2160',
        uhdX + uhdWidth/2,
        uhdY // Position above the UHD overlay
      );
    }

    // Then draw Siemens stars with updated sizing logic
    if (chartElements.siemensStars && siemensStarImage) {
      // Calculate base star size proportional to 2160p height with constraints
      const baseStarSize = 540; // Reduced from 600 to 540 (10% smaller)
      const isHighRes = canvasWidth > 3840 || canvasHeight > 2160;
      const starScale = Math.min(
        (canvasHeight / 2160) * (isHighRes ? 1.5 : 1),
        2.5
      );
      
      const standardStarRadius = Math.min(
        (baseStarSize / 2) * starScale,
        Math.min(resolutions.safety.width, resolutions.safety.height) * 0.135 // Reduced from 0.15 to 0.135 (10% smaller)
      );
      
      // Calculate center point for positioning
      const centerX = safeX + resolutions.safety.width/2;
      const centerY = safeY + resolutions.safety.height/2;
      
      // Determine if we're in a special resolution case
      const isBelowUHD = canvasWidth < 3840 || canvasHeight < 2160;
      
      let starPositions;
      
      if (isBelowUHD) {
        // For sub-UHD, place two stars horizontally
        const horizontalOffset = Math.min(
          resolutions.safety.width * 0.25,
          (resolutions.safety.width - standardStarRadius * 3) / 2
        );
        
        starPositions = [
          {
            x: centerX - horizontalOffset,
            y: centerY
          },
          {
            x: centerX + horizontalOffset,
            y: centerY
          }
        ];
      } else {
        // Standard positioning for all other cases (including wide aspects)
        const safeOffset = Math.max(
          circleInset + standardStarRadius,
          standardStarRadius * 1.5
        );
        
        // Adjust horizontal offset for wide aspects to prevent overlap
        const horizontalOffset = Math.min(
          resolutions.safety.width * 0.4,
          (resolutions.safety.width - standardStarRadius * 3) / 2
        );
        
        // Adjust vertical offset for wide aspects
        const verticalOffset = Math.min(
          resolutions.safety.height * 0.35,
          (resolutions.safety.height - standardStarRadius * 3) / 2
        );
        
        starPositions = [
          // Top Left
          {
            x: centerX - horizontalOffset,
            y: centerY - verticalOffset
          },
          // Top Right
          {
            x: centerX + horizontalOffset,
            y: centerY - verticalOffset
          },
          // Bottom Left
          {
            x: centerX - horizontalOffset,
            y: centerY + verticalOffset
          },
          // Bottom Right
          {
            x: centerX + horizontalOffset,
            y: centerY + verticalOffset
          }
        ];
      }
      
      // Draw stars with proper size and position
      starPositions.forEach(pos => {
        ctx.save();
        if (shouldApplySqueeze) {
          ctx.scale(1/squeezeRatio, 1);
          pos.x *= squeezeRatio;
        }
        ctx.globalCompositeOperation = 'source-over';
        drawSiemensStar(ctx, pos.x, pos.y, standardStarRadius);
        ctx.restore();
      });
    }

    // Draw corner circles
    if (chartElements.cornerCircles) {
      ctx.strokeStyle = chartColors.overlays.cornerCircles.stroke;
      ctx.fillStyle = chartColors.overlays.cornerCircles.text;
      ctx.lineWidth = 2;
      
      // Draw circles at corners within the safety margin area
      const corners = [
        { x: safeX + circleInset, y: safeY + circleInset },
        { x: safeX + resolutions.safety.width - circleInset, y: safeY + circleInset },
        { x: safeX + resolutions.safety.width - circleInset, y: safeY + resolutions.safety.height - circleInset },
        { x: safeX + circleInset, y: safeY + resolutions.safety.height - circleInset }
      ];

      corners.forEach(corner => {
        // Draw circle
        ctx.beginPath();
        ctx.arc(corner.x, corner.y, circleRadius, 0, Math.PI * 2);
        ctx.stroke();

        // Draw text
        ctx.font = `${fontSize * 0.8}px monospace`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText('circle', corner.x, corner.y);
      });
    }

    // Draw crosshair if enabled
    if (chartElements.crosshair) {
      drawCrosshair(ctx, canvasWidth/2, canvasHeight/2);
    }
  };


  // Update the main chart drawing useEffect
  useEffect(() => {
    if (framingChartState.resolution && siemensStarImage) {
      drawChart();
    }
  }, [
    drawChart,
    framingChartState.resolution,
    siemensStarImage
  ]);

  // Then update the ResizeObserver useEffect
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    // Debounce the drawChart call
    const debouncedDrawChart = debounce(() => {
      if (framingChartState.resolution) {
        drawChart();
      }
    }, 100); // 100ms delay

    const resizeObserver = new ResizeObserver(() => {
      debouncedDrawChart();
    });

    resizeObserver.observe(canvas.parentElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [framingChartState.resolution]);

  // Add this useEffect to monitor resolution changes
  useEffect(() => {
    if (framingChartState.resolution) {
      const isBelowUHD = isResolutionBelowUHD(framingChartState.resolution);
      
      // Disable UHD overlay if resolution is below/equal to UHD
      if (isBelowUHD) {
        setChartElements(prev => ({
          ...prev,
          uhdOverlay: false
        }));
      } else {
        // Re-enable UHD overlay for higher resolutions
        setChartElements(prev => ({
          ...prev,
          uhdOverlay: true
        }));
      }
    }
  }, [framingChartState.resolution]);

  const handleReset = () => {
    setFramingChartState(defaultState);
    setResetButtonText(<SlRefresh />);
    setTimeout(() => setResetButtonText(<SlRefresh />), 500);
  };

  const handleShare = () => {
    const canvas = canvasRef.current;
    if (!canvas || !framingChartState.resolution) return;

    // Get current date and time formatted as YYYYMMDDHHMM
    const now = new Date();
    const dateTime = now.toISOString()
      .replace(/[-:T]/g, '')
      .split('.')[0]
      .slice(0, 12);

    // Format show name or use default
    const showName = framingChartState.showName.trim() || 'Untitled';
    const formattedShowName = showName.replace(/\s+/g, '_').toUpperCase();

    // Get sensor mode (resolution without dimensions)
    const sensorMode = framingChartState.resolution.split('(')[0].trim();

    // Check if anamorphic
    const squeezeRatio = parseFloat(framingChartState.lensSqueeze);
    const isAnamorphic = squeezeRatio !== 1;
    const squeezeAmount = isAnamorphic ? `_${framingChartState.lensSqueeze.replace('x', '')}x` : '';

    // Base filename format with squeeze amount before timestamp
    const baseFileName = `${formattedShowName}_Framing_Chart_${framingChartState.camera}_${sensorMode}${squeezeAmount}`;

    if (isAnamorphic) {
      // Create a temporary canvas for the squeezed version
      const tempCanvas = document.createElement('canvas');
      const tempCtx = tempCanvas.getContext('2d');
      
      // Set the squeezed dimensions
      tempCanvas.width = canvas.width / squeezeRatio;
      tempCanvas.height = canvas.height;
      
      // Draw the squeezed version
      tempCtx.save();
      tempCtx.scale(1/squeezeRatio, 1);
      tempCtx.drawImage(canvas, 0, 0);
      tempCtx.restore();
      
      // Save squeezed version
      tempCanvas.toBlob((blob) => {
        saveAs(blob, `${baseFileName}_SQUEEZED_${dateTime}.png`);
        
        // Save unsqueezed version
        canvas.toBlob((blob) => {
          saveAs(blob, `${baseFileName}_UNSQUEEZED_${dateTime}.png`);
        });
      });
    } else {
      // Regular single file download for spherical
      canvas.toBlob((blob) => {
        saveAs(blob, `${baseFileName}_SPHERICAL_${dateTime}.png`);
      });
    }
    
    setShareButtonText(<SlLike />);
    setTimeout(() => setShareButtonText(<SlPaperPlane />), 500);
  };

  const handleDownload = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      // Get current date and time formatted as YYYYMMDDHHMM
      const now = new Date();
      const dateTime = now.toISOString()
        .replace(/[-:T]/g, '')
        .split('.')[0]
        .slice(0, 12);

      // Format show name or use default
      const showName = framingChartState.showName.trim() || 'Untitled';
      const formattedShowName = showName.replace(/\s+/g, '_').toUpperCase();

      // Get sensor mode (resolution without dimensions)
      const sensorMode = framingChartState.resolution.split('(')[0].trim();

      // Check if anamorphic
      const squeezeRatio = parseFloat(framingChartState.lensSqueeze);
      const isAnamorphic = squeezeRatio !== 1;
      const squeezeAmount = isAnamorphic ? `_${framingChartState.lensSqueeze.replace('x', '')}x` : '';

      // Base filename format with squeeze amount before timestamp
      const baseFileName = `${formattedShowName}_Framing_Chart_${framingChartState.camera}_${sensorMode}${squeezeAmount}`;
      
      if (isAnamorphic) {
        // Create a temporary canvas for the squeezed version
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');
        
        // Set the squeezed dimensions
        tempCanvas.width = canvas.width / squeezeRatio;
        tempCanvas.height = canvas.height;
        
        // Draw the squeezed version
        tempCtx.save();
        tempCtx.scale(1/squeezeRatio, 1);
        tempCtx.drawImage(canvas, 0, 0);
        tempCtx.restore();
        
        // Save both versions
        tempCanvas.toBlob((blob) => {
          saveAs(blob, `${baseFileName}_SQUEEZED_${dateTime}.png`);
          canvas.toBlob((blob) => {
            saveAs(blob, `${baseFileName}_UNSQUEEZED_${dateTime}.png`);
          });
        });
      } else {
        // Regular spherical download
        const fileName = `${baseFileName}_SPHERICAL_${dateTime}.png`;
        canvas.toBlob((blob) => {
          saveAs(blob, fileName);
        });
      }
      
      setDownloadButtonText(<SlLike />);
      setTimeout(() => setDownloadButtonText(<LuDownload />), 500);
    }
  };

  // Update drawSiemensStar to ensure visibility
  const drawSiemensStar = (ctx, centerX, centerY, radius) => {
    if (!siemensStarImage) {
      console.log('Siemens star image not loaded');
      return;
    }
    
    try {
      const x = centerX - radius;
      const y = centerY - radius;
      const size = radius * 2;
      
      // Draw the actual star image
      ctx.save();
      ctx.globalCompositeOperation = 'source-over';
      ctx.drawImage(siemensStarImage, x, y, size, size);
      ctx.restore();
    } catch (error) {
      console.error('Error drawing Siemens star:', error);
    }
  };

  // Update star size in calculateElementSizes
  const calculateElementSizes = (width, height) => {
    const baseSize = Math.min(width, height);
    return {
      circleRadius: baseSize * 0.05,  // 5% of smaller dimension
      starRadius: baseSize * 0.06,    // Increased to 6% for better visibility
      inset: baseSize * 0.1          // 10% inset from edge
    };
  };

  const currentSpacing = isMobile ? spacing.mobile : spacing.desktop;

  // Add helper functions for ratio handling
  const parseRatio = (input) => {
    // Remove all whitespace and convert to lowercase
    input = input.toLowerCase().replace(/\s+/g, '');

    // Check for decimal format first (e.g., 2.35 or 1.77)
    const decimalMatch = input.match(/^(\d+(?:\.\d+)?)$/);
    if (decimalMatch) {
      return parseFloat(input);
    }

    // Check for ratio format (e.g., 16:9, 16x9, 2.35:1)
    const ratioMatch = input.match(/^(\d+(?:\.\d+)?)[:x](\d+(?:\.\d+)?)$/);
    if (ratioMatch) {
      const [w, h] = ratioMatch.slice(1).map(Number);
      return w / h;
    }

    // Check for two numbers separated by space (e.g., 16 9, 2.35 1)
    const spaceMatch = input.match(/^(\d+(?:\.\d+)?)\s*(\d+(?:\.\d+)?)$/);
    if (spaceMatch) {
      const [w, h] = spaceMatch.slice(1).map(Number);
      return w / h;
    }

    return NaN;
  };

  const formatRatio = (ratio) => {
    // For decimal inputs like 2.35, preserve as "2.35:1"
    if (Number.isFinite(ratio) && !Number.isInteger(ratio)) {
      return `${ratio.toFixed(3)}:1`;
    }

    // For integer ratios, use the GCD reduction
    const tolerance = 0.0001;
    for (let denominator = 1; denominator <= 16; denominator++) {
      const numerator = ratio * denominator;
      if (Math.abs(numerator - Math.round(numerator)) < tolerance) {
        let n = Math.round(numerator);
        let d = denominator;
        const gcd = getGCD(n, d);
        n = n / gcd;
        d = d / gcd;
        return `${n}:${d}`;
      }
    }

    return `${ratio.toFixed(3)}:1`;
  };

  const getGCD = (a, b) => {
    a = Math.abs(a);
    b = Math.abs(b);
    while (b) {
      const temp = b;
      b = a % b;
      a = temp;
    }
    return a;
  };

  return (
    <div className={`framing-chart-generator ${spacing.padding} ${colors.bgColor} ${colors.textColor}`}>
      <div className={layout.flex.column}>
        <div className={`grid gap-4 ${spacing.fieldGap}`}>
          {/* Camera Info Section */}
          {/* <div className="grid grid-cols-2 gap-4"> */}
          <div className={`grid ${layout.grid.brandCamera} gap-2 ${currentSpacing.fieldGap}`}>
            <div>
              <label className={commonStyles.labelClass}>Brand</label>
              <select
                ref={brandRef}
                name="brand"
                value={framingChartState.brand}
                onChange={handleInputChange}
                onWheel={handleBrandWheel}
                onTouchStart={handleBrandTouch}
                className={commonStyles.commonInputClass}
              >
                {brands.map(brand => (
                  <option key={brand} value={brand}>{brand}</option>
                ))}
              </select>
            </div>
            <div>
              <label className={commonStyles.labelClass}>Camera</label>
              <select
                ref={cameraRef}
                name="camera"
                value={framingChartState.camera}
                onChange={handleInputChange}
                onWheel={handleCameraWheel}
                onTouchStart={handleCameraTouch}
                className={commonStyles.commonInputClass}
                disabled={!framingChartState.brand}
              >
                {availableCameras.map(camera => (
                  <option key={camera} value={camera}>{camera}</option>
                ))}
              </select>
            </div>
          </div>

          {/* Resolution and Codec Section */}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className={commonStyles.labelClass}>Resolution</label>
              <select
                ref={resolutionRef}
                name="resolution"
                value={framingChartState.resolution}
                onChange={handleInputChange}
                onWheel={handleResolutionWheel}
                onTouchStart={handleResolutionTouch}
                className={commonStyles.commonInputClass}
                disabled={!framingChartState.camera}
              >
                {availableResolutions.map(resolution => (
                  <option key={resolution} value={resolution}>{resolution}</option>
                ))}
              </select>
            </div>
            <div>
              <label className={commonStyles.labelClass}>Codec</label>
              <select
                name="codec"
                value={framingChartState.codec}
                onChange={handleInputChange}
                className={commonStyles.commonInputClass}
                disabled={!framingChartState.resolution}
              >
                {availableCodecs.map(codec => (
                  <option key={codec} value={codec}>{codec}</option>
                ))}
              </select>
            </div>
          </div>

          {/* Production Info Section */}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className={commonStyles.labelClass}>Show Name</label>
              <input
                type="text"
                value={framingChartState.showName}
                onChange={(e) => setFramingChartState(prev => ({ ...prev, showName: e.target.value }))}
                className={commonStyles.commonInputClass}
                placeholder="Production Name"
              />
            </div>
            <div>
              <label className={commonStyles.labelClass}>DoP</label>
              <input
                type="text"
                value={framingChartState.dopName}
                onChange={(e) => setFramingChartState(prev => ({ ...prev, dopName: e.target.value }))}
                className={commonStyles.commonInputClass}
                placeholder="Director of Photography"
              />
            </div>
          </div>

          {/* Chart Settings Section */}
          <div className={`grid ${layout.grid.threeColumns} gap-2 ${currentSpacing.fieldGap}`}>
            <div>
              <label className={commonStyles.labelClass}>Safety Margin (%)</label>
              <input
                type="number"
                value={framingChartState.safetyMargin}
                onChange={(e) => setFramingChartState(prev => ({ ...prev, safetyMargin: Number(e.target.value) }))}
                className={commonStyles.commonInputClass}
                min="0"
                max="20"
              />
            </div>
            <div>
              <label className={commonStyles.labelClass}>Aspect Ratio</label>
              {!isCustomInput ? (
                <select
                  value={framingChartState.aspectRatio}
                  onChange={(e) => {
                    if (e.target.value === 'Custom') {
                      setIsCustomInput(true);
                      setCustomRatio('');
                      setFramingChartState(prev => ({ ...prev, aspectRatio: '' }));
                    } else {
                      setFramingChartState(prev => ({ ...prev, aspectRatio: e.target.value }));
                    }
                  }}
                  className={commonStyles.commonInputClass}
                >
                  <option value="4:3">4:3</option>
                  <option value="16:9">16:9</option>
                  <option value="1.85:1">1.85:1</option>
                  <option value="2:1">2:1</option>
                  <option value="2.35:1">2.35:1</option>
                  <option value="2.39:1">2.39:1</option>
                  <option value="2.40:1">2.40:1</option>
                  {customRatios.map(ratio => (
                    <option key={ratio} value={ratio}>{ratio}</option>
                  ))}
                  <option value="Custom">Custom</option>
                </select>
              ) : (
                <input
                  type="text"
                  value={customRatio}
                  onChange={(e) => {
                    if (/^[\d.:x\s]*$/.test(e.target.value)) {
                      const cleanedValue = e.target.value.replace(/\s+/g, ' ');
                      const formattedValue = cleanedValue.replace(/\s/g, ':');
                      setCustomRatio(formattedValue);
                    }
                  }}
                  onBlur={() => {
                    if (customRatio) {
                      const parsedRatio = parseRatio(customRatio);
                      if (!isNaN(parsedRatio)) {
                        const formattedRatio = formatRatio(parsedRatio);
                        if (!customRatios.includes(formattedRatio)) {
                          setCustomRatios(prev => [...prev, formattedRatio]);
                        }
                        setFramingChartState(prev => ({ ...prev, aspectRatio: formattedRatio }));
                        setCustomRatio('');
                        setIsCustomInput(false);
                      }
                    } else {
                      setIsCustomInput(false);
                      setFramingChartState(prev => ({ ...prev, aspectRatio: '16:9' })); // Default value
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.target.blur();
                    } else if (e.key === 'Escape') {
                      setIsCustomInput(false);
                      setCustomRatio('');
                      setFramingChartState(prev => ({ ...prev, aspectRatio: '16:9' })); // Default value
                    }
                  }}
                  placeholder="Enter ratio (e.g., 16:9)"
                  className={commonStyles.commonInputClass}
                  autoFocus
                />
              )}
            </div>
            <div>
              <label className={commonStyles.labelClass}>Lens Squeeze</label>
              <select
                value={framingChartState.lensSqueeze}
                onChange={(e) => setFramingChartState(prev => ({ ...prev, lensSqueeze: e.target.value }))}
                className={commonStyles.commonInputClass}
              >
                <option value="1.0x">Spherical</option>
                <option value="1.25x">1.25x Anamorphic</option>
                <option value="1.3x">1.3x Anamorphic</option>
                <option value="1.33x">1.33x Anamorphic</option>
                <option value="1.5x">1.5x Anamorphic</option>
                <option value="1.55x">1.55x Anamorphic</option>
                <option value="1.65x">1.65x Anamorphic</option>
                <option value="1.8x">1.8x Anamorphic</option>
                <option value="2.0x">2.0x Anamorphic</option>
              </select>
            </div>
          </div>
          
          {/* Chart Display */}
          <div className="relative w-full" style={{ height: 'auto' }}>
            <canvas
              ref={canvasRef}
              className="w-full border border-gray-300 rounded bg-gray-800"
            />
            {!framingChartState.resolution && (
              <div className="absolute inset-0 flex items-center justify-center text-gray-400">
                Enter camera and resolution information to display the framing chart
              </div>
            )}
          </div>

          <CalculatorButtons
            onLog={handleDownload}
            onShare={handleShare}
            onReset={handleReset}
            logButtonIcon={downloadButtonText}
            shareButtonIcon={shareButtonText}
            resetButtonIcon={resetButtonText}
            logButtonText="Download"
            copyText={`Frame Chart: ${framingChartState.camera} - ${framingChartState.aspectRatio}${
              parseFloat(framingChartState.lensSqueeze) !== 1 ? ` (${framingChartState.lensSqueeze}x)` : ''
            } (${framingChartState.safetyMargin}% safety)`}
          />
        </div>
      </div>
    </div>
  );
};

export default FramingChartGenerator;